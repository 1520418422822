<template>
  <header class="header" :class="{ white: white, scrolled: scrolled }">
    <div class="header__container container" :class="{ showed: scrolledUp }">
      <router-link to="/" class="header__logo">LOMOCAST</router-link>
      <nav class="header__menu">
        <button
          type="button"
          class="toggle-menu js-toggle-menu"
          @click="menuState = !menuState"
        >
          <div
            class="sandwich js-sandwich"
            :class="{ 'is-active': menuState }"
          >
            <span class="sw-topper"></span>
            <span class="sw-bottom"></span>
            <span class="sw-footer"></span>
          </div>
        </button>
        <transition name="fade">
          <ul class="main-nav" v-if="isDesktop || menuState">
            <li class="main-nav__item">
              <router-link
                class="main-nav__link"
                active-class="active"
                to="/models"
              >
                Models
              </router-link>
            </li>
            <li class="main-nav__item">
              <router-link
                class="main-nav__link"
                active-class="active"
                to="/locations"
              >
                Locations
              </router-link>
            </li>
            <li class="main-nav__item">
              <router-link
                class="main-nav__link"
                active-class="active"
                to="/animals"
              >
                Animals
              </router-link>
            </li>
            <li class="main-nav__item">
              <router-link
                class="main-nav__link"
                active-class="active"
                to="/jobs"
              >
                Jobs
              </router-link>
            </li>
            <li class="main-nav__item">
              <router-link
                v-if="!Object.keys(currentUser).length"
                class="main-nav__link"
                active-class="active"
                to="/login"
              >
                Login
              </router-link>
              <a v-else-if="isDesktop"
                class="main-nav__link"
                href="/my" target="_blank"
              >
                {{ currentUser.firstName }}
              </a>
            </li>
            <li v-if="!isDesktop" class="main-nav__item">
              <ul class="secondary-nav">
                <li class="secondary-nav__item">
                  <router-link
                    class="secondary-nav__link"
                    active-class="active"
                    to="about"
                  >
                    About
                  </router-link>
                </li>
                <li class="secondary-nav__item">
                  <router-link
                    class="secondary-nav__link"
                    active-class="active"
                    to="contact"
                  >
                    Contact
                  </router-link>
                </li>
                <li class="secondary-nav__item">
                  <router-link
                    class="secondary-nav__link"
                    active-class="active"
                    to="legal"
                  >
                    Legal
                  </router-link>
                </li>
              </ul>
            </li>
          </ul>
        </transition>
      </nav>
    </div>
    <div class="header__links">
      <router-link to="/" class="header__link header__link_top header__link_left">LO</router-link>
      <router-link to="/" class="header__link header__link_top header__link_right">MO</router-link>
      <router-link to="/" class="header__link header__link_bottom header__link_left">CA</router-link>
      <router-link to="/" class="header__link header__link_bottom header__link_right">ST</router-link>
    </div>
  </header>
</template>

<script>
import { computed, ref } from "vue";
import { useStore } from "vuex";
export default {
  props: {
    white: Boolean,
    footerState: Boolean
  },
  emits: ["update:footerState", "update:white"],
  setup() {
    const menuState = ref(false);
    const savedHeaderState = ref(false);
    const savedFooterState = ref(false);
    const isDesktop = window.innerWidth > 1023 ? ref(true) : ref(false);
    const store = useStore();
    const currentUser = computed(() => store.state.user);
    const scrolled = ref(false);
    const scrolledUp = ref(false);
    const prevScroll = ref(0);

    return {
      menuState,
      isDesktop,
      savedHeaderState,
      savedFooterState,
      currentUser,
      scrolled,
      scrolledUp,
      prevScroll
    };
  },
  watch: {
    menuState(val) {
      if (val) {
        document.body.classList.add("overflow");
        this.savedHeaderState = this.white;
        this.savedFooterState = this.footerState;
        this.$emit("update:white", false);
        this.$emit("update:footerState", false);
      } else {
        document.body.classList.remove("overflow");
        this.$emit("update:white", this.savedHeaderState);
        this.$emit("update:footerState", this.savedFooterState);
      }
    },
    $route() {
      this.menuState = false;
    }
  },
  mounted() {
    window.addEventListener("scroll", () => {
      this.scrolled = window.scrollY > 0;
      this.scrolledUp =
        window.scrollY < this.prevScroll && window.scrollY > 250;
      this.prevScroll = window.scrollY;
    });
  },
  methods: {
    // sandwichHandler() {
    //   this.menuState = !this.menuState;
    //   if (this.menuState) {
    //     document.body.classList.add("overflow");
    //     this.savedHeaderState = this.white;
    //     this.savedFooterState = this.footerState;
    //     this.$emit("update:white", false);
    //     this.$emit("update:footerState", false);
    //   } else {
    //     document.body.classList.remove("overflow");
    //     this.$emit("update:white", this.savedHeaderState);
    //     this.$emit("update:footerState", this.savedFooterState);
    //   }
    // }
  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/style/variables.scss";
.header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  pointer-events: none;
  transition: color 0.3s;
  z-index: 10;
  &-bottom {
    top: auto;
    bottom: 0;
  }
  &.white {
    a {
      color: #fff;
    }
  }
  &.scrolled {
    .header__links {
      opacity: 0;
      transition: opacity 0.3s;
    }
    .header__link {
      height: 0;
      transition: height 0.3s step-end;
    }
    @media (min-width: 1024px) {
      .header__container {
        transform: translateY(-90px);
        opacity: 0;
        &.showed {
          justify-content: space-between;
          transform: none;
          opacity: 1;
          background-color: #fff;
          box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
          .header__logo {
            display: block;
          }
        }
      }
    }
  }
  &__container {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    position: relative;
    padding-top: 32px;
    padding-bottom: 34px;
    transition: all 0.3s;
    @media screen and (max-width: 767px) {
      padding-top: 18px;
      padding-bottom: 18px;
    }
  }
  &__links {
    transition: opacity 0.3s;
  }
  &__link {
    font-size: 108px;
    line-height: 78px;
    height: 81px;
    pointer-events: all;
    position: fixed;
    transition: height 0.3s step-start;
    overflow: hidden;
    z-index: 1;
    &_top {
      top: 32px;
    }
    &_bottom {
      bottom: 32px;
    }
    &_left {
      left: 27px;
    }
    &_right {
      right: 32px;
    }
    @media screen and (max-width: 1023px) {
      font-size: 50px;
      line-height: 35px;
      height: 37px;
      &_top {
        top: 18px;
      }
      &_bottom {
        bottom: 18px;
      }
      &_left {
        left: 18px;
      }
      &_right {
        right: 18px;
      }
    }
  }
  &__logo {
    font-size: 20px;
    line-height: 14px;
    display: none;
  }
  &__menu {
    pointer-events: all;
    @media screen and (max-width: 1023px) {
      // margin-top: 2px;
    }
  }
}
.main-nav {
  display: flex;
  align-items: center;
  justify-content: center;
  @media only screen and (max-width: 1023px) {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #fff;
    flex-direction: column;
    padding: 65px 0 87px;
    overflow: auto;
    font-size: 30px;
    line-height: 1.6;
  }
  &__item {
    &:not(:first-child) {
      margin-left: 36px;
      @media only screen and (max-width: 1023px) {
        margin: 0;
      }
    }
    @media only screen and (max-width: 1023px) {
      > .main-nav__link {
        font-size: 30px;
        line-height: 1.4;
        margin-bottom: 10px;
      }
    }
  }
  &__link {
    font-size: calc(16px + (20 - 16) * ((100vw - 375px) / (1680 - 375)));
    line-height: 14px;
    display: block;
    &:hover {
      color: $hoverColor;
    }
    &.active {
      color: $mainBlue;
    }
  }
}
.secondary-nav {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 20px;
  margin-top: 28px;
}

/*Sandwich*/
.toggle-menu {
  position: relative;
  // float: right;
  // width: 32px;
  // height: 32px;
  display: none;
  padding: 0;
  z-index: 100;
  border: none;
  background-color: transparent;
  @media only screen and (max-width: 1023px) {
    display: block;
    // &:before {
    //   content: "";
    //   position: absolute;
    //   top: -10px;
    //   left: -10px;
    //   right: -10px;
    //   bottom: -10px;
    // }
  }
  &:focus {
    outline: none;
  }
}

$w: 16px;
$h: 3px;
.sandwich {
  // position: absolute;
  // left: 0;
  // right: 0;
  // margin: auto;
  z-index: 200;
  // top: 50%;
  // transform: translateY(-50%);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 35px;
}
.sw-topper {
  display: block;
  position: relative;
  transform-origin: center center;
  width: $w;
  height: $h;
  background: currentColor;
  border: none;
  transition: transform 0.3s;
  .header.is-fixed &,
  .header.is-open & {
    background-color: #000;
  }
}
.sw-bottom {
  overflow: hidden;
  display: block;
  position: relative;
  transition: transform 0.3s;
  width: $w;
  height: $h;
  background: currentColor;
  margin: 3px 0;
}
.sw-footer {
  display: block;
  position: relative;
  width: $w;
  height: $h;
  transform-origin: center center;
  background: currentColor;
  border: none;
  transition: transform 0.3s;
  .header.is-fixed &,
  .header.is-open & {
    background-color: #363636;
  }
}
.sandwich.is-active {
  .sw-topper {
    width: 18px;
    transform: translateY(7px) rotate(45deg);
  }
  .sw-bottom {
    transform: scale(0);
  }
  .sw-footer {
    width: 18px;
    transform: translateY(-5px) rotate(-45deg);
  }
}
</style>
