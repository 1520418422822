<template>
  <div class="we-cast">
    <div class="container we-cast__container">
      <h3 class="we-cast__title content-title">We Cast</h3>
      <div v-if="gridElements && gridElements.length" class="row we-cast__wrap">
        <div
          v-for="(item, key) in gridElements"
          :key="key"
          class="we-cast__col col-4 col-sm-12"
        >
          <router-link
            class="we-cast__item"
            :to="`/${item.label.toLowerCase()}`"
          >
            <img
              :srcset="item.thumbnail.srcset"
              :src="item.thumbnail.sizes.large"
              alt=""
              class="we-cast__img"
            />
            <div class="we-cast__pill">{{ item.label }}</div>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed } from "vue";
export default {
  props: {
    data: {
      type: Array,
      default: () => []
    }
  },
  setup(props) {
    const gridElements = computed(() => props.data);

    return {
      gridElements
    };
  }
};
</script>

<style lang="scss" scoped>
@import "~@/assets/style/views/home/weCast.scss";
</style>
