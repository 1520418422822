<template>
  <div class="login">
    <transition name="camillaLoader">
      <div v-if="!isPageReady || !imagesReady" class="loader"></div>
    </transition>
    <transition name="fadetext" @before-leave="beforeLeave">
      <div v-if="!isPageReady || !imagesReady" class="loader-text">
        Loading <span>{{ Math.round(progress) }}%</span>
      </div>
    </transition>

    <Header />

    <div class="container form__container">
      <h2 class="form__title">Client contact</h2>
      <div class="form__description">
        <p>
          If you're looking for humans, animals or locations for your production, please contact us using the form below, or email us at <a href="mailto:info@lomocast.com">info@lomocast.com</a>
        </p>
      </div>
    </div>

    <div class="login__container">
      <form class="form" id="form">
        <div class="form__container container">
          <div class="form-group" :class="{ error: v.user.firstName.$errors.length }">
            <label class="form-label">First name</label>
            <input
              v-model="user.firstName"
              class="form-input"
              type="text"
              placeholder="Enter first name"
            />
            <p
              v-for="(error, index) of v.user.firstName.$errors"
              :key="index"
              class="error-message"
            >
              {{ error.$message }}
            </p>
          </div>
          <div class="form-group" :class="{ error: v.user.lastName.$errors.length }">
            <label class="form-label">Last name</label>
            <input
              v-model="user.lastName"
              class="form-input"
              type="text"
              placeholder="Enter last name"
            />
            <p
              v-for="(error, index) of v.user.lastName.$errors"
              :key="index"
              class="error-message"
            >
              {{ error.$message }}
            </p>
          </div>
          <div class="form-group" :class="{ error: v.user.email.$errors.length }">
            <label class="form-label">E-mail</label>
            <input
              v-model="user.email"
              class="form-input"
              type="text"
              placeholder="Enter e-mail"
            />
            <p
              v-for="(error, index) of v.user.email.$errors"
              :key="index"
              class="error-message"
            >
              {{ error.$message }}
            </p>
          </div>
          <div class="form-group" :class="{ error: v.user.phone.$errors.length }">
            <label class="form-label">Phone</label>
            <input
              v-model="user.phone"
              class="form-input"
              type="text"
              placeholder="Enter phone"
            />
            <p
              v-for="(error, index) of v.user.phone.$errors"
              :key="index"
              class="error-message"
            >
              {{ error.$message }}
            </p>
          </div>
          <div class="form-group" :class="{ error: v.user.companyName.$errors.length }">
            <label class="form-label">Company name</label>
            <input
              v-model="user.companyName"
              class="form-input"
              type="text"
              placeholder="Enter company name"
            />
            <p
              v-for="(error, index) of v.user.companyName.$errors"
              :key="index"
              class="error-message"
            >
              {{ error.$message }}
            </p>
          </div>
          <div class="form-group" :class="{ error: v.user.description.$errors.length }">
            <label class="form-label">Message</label>

            <textarea
              rows="6"
              v-model="user.description"
              class="form-input"
            />
            <p
              v-for="(error, index) of v.user.description.$errors"
              :key="index"
              class="error-message"
            >
              {{ error.$message }}
            </p>
          </div>
          <div
            class="form-group"
            :class="{ error: v.politic.$errors.length }"
          >
            <label class="checkbox">
              <input
                v-model="politic"
                class="checkbox__input"
                type="checkbox"
                name="politic"
              />
              <div class="checkbox__icon"></div>
              <div class="checkbox__label">
                I agree to allow LomoCast to store and process my personal data.
                <a
                  class="info"
                  href="/legal"
                  @click.prevent="showLegalPopup = true"
                >
                  Read all about it here
                </a>
              </div>
            </label>
          </div>
          <div class="form__submit">
            <button
              :disabled="v.$invalid"
              class="btn"
              type="submit"
              @click.prevent="submitHandler"
            >
              send
            </button>
            <!-- <button v-if="v.$invalid" disabled class="btn" type="submit">
              submit
            </button> -->
          </div>
        </div>
      </form>
    </div>
    <Footer 
      v-if="isPageReady"
      :legalText="homePage.acf.legalText"
      :contactText="homePage.acf.contactText"
    />
    <teleport to="[data-error-popup]">
      <error-popup
        v-model:showPopup="showErrorPopup"
        :errorMessage="errorMessage"
        :white="true"
      />
    </teleport>
    <teleport to="[data-error-popup]">
      <error-popup
        v-if="showLegalPopup"
        v-model:showPopup="showLegalPopup"
        :content="legalText"
        :title="'Legal'"
        :white="true"
      />
    </teleport>
  </div>
</template>

<script>
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
import useVuelidate from "@vuelidate/core";
import { email as emailValidator, required, helpers } from "@vuelidate/validators";
import { computed, nextTick, onMounted, ref } from "vue";
import { useMutation } from "@vue/apollo-composable";
import createClientApplicantMutation from "@/graphql/createClientApplicant.mutation.gql"
import ErrorPopup from "@/components/ErrorPopup.vue";
import { useStore } from 'vuex';

export default {
  components: {
    Header,
    Footer,
    ErrorPopup
  },
  setup () {
    const store = useStore();
    const homePage = computed(() => store.state.pageHome);
    const isPageReady = computed(() => homePage.value.acf ? true : false);
    const progress = computed(() => (isPageReady.value ? 100 : 0));
    const imagesReady = ref(false);

    const user = ref({
        firstName: "",
        lastName: "",
        email: "",
        phone: "",
        companyName: "",
        description: ""
    });
    const currentUser = computed(() => store.state.user);
    const legalText = computed(() => store.state.pageHome.acf.legalText);
    const firstNameErrorMessage = "First name field cannot be empty";
    const lastNameErrorMessage = "Last name field cannot be empty";
    const emailRequiredErrorMessage = "Email field cannot be empty";
    const emailErrorMessage = "Email not valid";
    const phoneErrorMessage = "Password field cannot be empty";
    const companyNameErrorMessage = "Company name field cannot be empty";
    const descriptionErrorMessage = "Message field cannot be empty";
    const politic = ref(false);
    const showErrorPopup = ref(false);
    const showLegalPopup = ref(false);
    const errorMessage = ref("");
    const checkboxRequiredRule = value => {
      if (Object.keys(currentUser.value).length) {
        return true;
      }
      return value === true;
    };

    onMounted(() => {
      store.dispatch("onFetchHome");
      setTimeout(() => {
        imagesReady.value = true;
      }, 200);
    });

    const { mutate: createClientApplicant } = useMutation(createClientApplicantMutation);

    const rules = {
      user: {
        firstName: {
          required: helpers.withMessage(firstNameErrorMessage, required),
          $autoDirty: true
        },
        lastName: {
          required: helpers.withMessage(lastNameErrorMessage, required),
          $autoDirty: true
        },
        email: {
          required: helpers.withMessage(
            emailRequiredErrorMessage,
            (value) => value ? true : false
          ),
          emailValidator: helpers.withMessage(emailErrorMessage, emailValidator),
          $autoDirty: true
        },
        phone: {
          required: helpers.withMessage(phoneErrorMessage, required),
          $autoDirty: true
        },
        companyName: {
          required: helpers.withMessage(companyNameErrorMessage, required),
          $autoDirty: true
        },
        description: {
          required: helpers.withMessage(descriptionErrorMessage, required),
          $autoDirty: true
        }
      },
      politic: { checkboxRequiredRule, $autoDirty: true }
    };
    const v = useVuelidate(rules, {
      user,
      politic
    });
    return {
      user,
      politic,
      createClientApplicant,
      showErrorPopup,
      showLegalPopup,
      legalText,
      errorMessage,
      homePage,
      isPageReady,
      imagesReady,
      progress,
      v
    };
  },
  methods: {
    submitHandler() {
      this.v.$touch();
      if (this.v.$error) {
        return;
      }

      nextTick(() => {
        this.createClientApplicant({
          attributes: this.user
        })
        .then(({ data }) => {
          if (!data.createClientApplicant.clientApplicant.id) {
            this.errorHandler("Something went wrong.");
            return;
          }
          this.errorHandler("Your application has been received. A confirmation will be emailed to you after verification.");
        })
        .catch(e => {
          const message = e.networkError?.result?.errors?.map(error => error.message)?.join(', ') || e.message;
          this.errorHandler(message);
        });
      });
    },
    errorHandler(error) {
      this.showErrorPopup = true;
      this.errorMessage = error;
    }
  }
};
</script>

<style lang="scss" scoped>
@import "~@/assets/style/views/client-registration/registration.scss";
</style>
