<template>
  <div class="work">
    <div class="container work__container">
      <h3 class="work__title content-title">{{ work.title }}</h3>
      <div class="work__row row">
        <div
          v-for="(item, index) in work.cta"
          :key="index"
          class="work__item work-item col-6 col-sm-12"
        >
          <div class="work-item__text" v-html="item.text"></div>
          <div class="work-item__btn">
            <router-link class="btn" :to="item.button_link">
              {{ item.button }}
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    work: Object
  },
  setup() {
    return {};
  }
};
</script>

<style lang="scss" scoped>
@import "~@/assets/style/views/home/work.scss";
</style>
