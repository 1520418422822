<template>
  <div class="about-page">
    <transition name="camillaLoader">
      <div v-if="!isPageReady || !imagesReady" class="loader"></div>
    </transition>
    <transition name="fadetext" @before-leave="beforeLeave">
      <div v-if="!isPageReady || !imagesReady" class="loader-text">
        Loading <span>{{ Math.round(progress) }}%</span>
      </div>
    </transition>
    <transition name="fade">
      <div v-if="sendingData" class="loader"></div>
    </transition>
    <transition name="fade">
      <div v-if="sendingData" class="loader-progress">
        <div
          class="loader-progress__info"
          :style="addedImages ? '' : 'justify-content:center;'"
        >
          <label>Transferring, please wait</label>
          <label v-if="addedImages">
            {{ Math.round((100 * uploadedImages) / addedImages) }}%
          </label>
        </div>
        <div v-if="addedImages" class="loader-progress__background"></div>
        <transition name="fade">
          <div
            v-if="addedImages"
            class="loader-progress__tracker"
            :style="`width: ${(100 * uploadedImages) / addedImages}%`"
          ></div>
        </transition>
      </div>
    </transition>
    <Header />
    <apply-form
      v-model:confirmation="confirmation"
      :dataReady="dataReady"
      @sending="sendingHandler"
      @image-added="imageAdded"
      @image-uploaded="imageUploaded"
    />
    <Footer />
    <teleport to="[data-error-popup]">
      <div v-if="confirmation" class="job-popup">
        <div class="job-popup__wrap">
          <button
            type="button"
            class="popup__close"
            @click="confirmation = !confirmation"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="15.556"
              height="15.556"
              viewBox="0 0 15.556 15.556"
            >
              <rect
                width="3"
                height="19"
                transform="translate(0 2.121) rotate(-45)"
              />
              <rect
                width="3"
                height="19"
                transform="translate(2.121 15.556) rotate(-135)"
              />
            </svg>
          </button>
          <div class="job-popup__container">
            <confirmation-popup v-model:confirmation="confirmation" />
          </div>
        </div>
      </div>
    </teleport>
  </div>
</template>

<script>
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
import ApplyForm from "@/components/jobs/ApplyForm.vue";
import ConfirmationPopup from "@/components/jobs/confirmationPopup.vue";
import { computed, onMounted, ref } from "vue";
import { useStore } from "vuex";
export default {
  components: {
    Header,
    Footer,
    ApplyForm,
    ConfirmationPopup
  },
  setup() {
    const store = useStore();
    const isPageReady = computed(() => (aboutPage.value.acf ? true : false));
    const aboutPage = computed(() => store.state.pageAbout);
    const progress = computed(() => (aboutPage.value.acf ? 100 : 0));
    const confirmation = ref(false);
    const dataReady = ref(false);
    const sendingData = ref(false);
    const addedImages = ref(0);
    const uploadedImages = ref(0);
    const beforeLeave = () => {
      progress.value = 100;
    };
    const imagesReady = ref(false);
    onMounted(() => {
      store.dispatch("onFetchAbout");
      setTimeout(() => {
        imagesReady.value = true;
      }, 200);
    });
    return {
      progress,
      isPageReady,
      aboutPage,
      beforeLeave,
      imagesReady,
      confirmation,
      dataReady,
      sendingData,
      addedImages,
      uploadedImages
    };
  },
  methods: {
    sendingHandler(event) {
      this.sendingData = event;
      if (this.sendingData && !this.addedImages) {
        this.dataReady = true;
      }
    },
    imageAdded(value) {
      this.addedImages += value;
      if (this.uploadedImages !== this.addedImages) {
        this.dataReady = false;
      }
    },
    imageUploaded(value) {
      this.uploadedImages += value;
      if (this.uploadedImages === this.addedImages) {
        this.$nextTick(() => {
          setTimeout(() => {
            this.dataReady = true;
          }, 300);
        });
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import "~@/assets/style/views/about/about.scss";
</style>
