<template>
  <div class="portfolio-page">
    <transition name="camillaLoader">
      <div v-if="!imagesReady" class="loader"></div>
    </transition>
    <transition name="fadetext">
      <div v-if="!imagesReady" class="loader-text">
        Loading <span>{{ Math.round(progress) }}%</span>
      </div>
    </transition>
    <Header />
    <suspense>
      <portfolio-content
        v-model:progress="progress"
        v-model:imagesReady="imagesReady"
      />
    </suspense>
    <work-with-us v-if="isPageReady" :work="homePage.acf.work" />
    <Footer />
  </div>
</template>

<script>
import { useStore } from "vuex";
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
import WorkWithUs from "@/components/home/Work.vue";
import PortfolioContent from "@/components/portfolio/PortfolioContent.vue";
import { computed, onMounted, ref } from "vue";
export default {
  components: {
    Header,
    Footer,
    WorkWithUs,
    PortfolioContent
  },
  setup() {
    const store = useStore();
    const isPageReady = computed(() => (homePage.value.acf ? true : false));
    const homePage = computed(() => store.state.pageHome);
    const imagesReady = ref(false);
    const progress = ref(0);
    onMounted(() => {
      store.dispatch("onFetchHome");
    });
    return {
      isPageReady,
      homePage,
      imagesReady,
      progress
    };
  }
};
</script>

<style lang="scss" scoped></style>
