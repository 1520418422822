<template>
  <div class="login-popup">
    <form class="form" @submit.prevent="submitHandler">
      <div class="form__container container">
        <div class="form__title">Login to apply</div>
        <div class="form__description">
          Please login to apply.
        </div>
        <div
          class="form-group"
          :class="{ error: v.emailAddress.$errors.length }"
        >
          <label class="form-label">E-mail</label>
          <input
            v-model="emailAddress"
            class="form-input"
            type="text"
            placeholder="Enter e-mail"
          />
          <p
            v-for="(error, index) of v.emailAddress.$errors"
            :key="index"
            class="error-message"
          >
            {{ error.$message }}
          </p>
        </div>
        <div class="form-group" :class="{ error: v.password.$errors.length }">
          <label class="form-label">Password</label>
          <input
            v-model="password"
            class="form-input"
            type="password"
            placeholder="Enter password"
          />
          <p
            v-for="(error, index) of v.password.$errors"
            :key="index"
            class="error-message"
          >
            {{ error.$message }}
          </p>
        </div>
        <div class="info">
          <a href='/forgot-password'> Forgot password? </a>
        </div>
        <div class="form__submit login-popup__submit">
          <button type="button" class="login-popup__cancel">cancel</button>

          <button
            :disabled="v.$invalid"
            class="btn"
            type="submit"
            @click.prevent="submitHandler"
          >
            continue
          </button>
        </div>
      </div>
    </form>
    <teleport to="[data-error-popup]">
      <error-popup
        v-model:showPopup="showErrorPopup"
        :errorMessage="errorMessage"
        :white="true"
      />
    </teleport>
  </div>
</template>

<script>
import useVuelidate from "@vuelidate/core";
import { email, required, helpers } from "@vuelidate/validators";
import { nextTick, ref } from "vue";
import { useMutation } from "@vue/apollo-composable";
import signInMutation from "@/graphql/singIn.mutation.gql";
import ErrorPopup from "@/components/ErrorPopup.vue";
import { useStore } from "vuex";
export default {
  components: {
    ErrorPopup
  },
  emits: ["loged-in"],
  setup() {
    const store = useStore();
    const emailAddress = ref("");
    const password = ref("");
    const emailRequiredErrorMessage = "Email field cannot be empty";
    const emailErrorMessage = "Email not valid";
    const passwordErrorMessage = "Password field cannot be empty";
    const showErrorPopup = ref(false);
    const errorMessage = ref("");
    const { mutate: singIn } = useMutation(signInMutation, {
      context: {
        headers: {
          "JWT-AUD": "web"
        }
      }
    });
    const rules = {
      emailAddress: {
        required: helpers.withMessage(emailRequiredErrorMessage, value =>
          value ? true : false
        ),
        email: helpers.withMessage(emailErrorMessage, email),
        $autoDirty: true
      },
      password: {
        required: helpers.withMessage(passwordErrorMessage, required),
        $autoDirty: true
      }
    };
    const v = useVuelidate(rules, {
      emailAddress,
      password
    });

    return {
      emailAddress,
      password,
      singIn,
      showErrorPopup,
      errorMessage,
      store,
      v
    };
  },
  methods: {
    submitHandler() {
      this.v.$touch();
      if (this.v.$error) {
        return;
      }
      nextTick(() => {
        this.singIn({
          input: {
            email: this.emailAddress,
            password: this.password
          }
        })
          .then(({ data }) => {
            if (!data.signIn.session) {
              this.errorHandler(data);
              return;
            }
            const token = data.signIn.session.token;
            window.localStorage.setItem("lomoCastToken", token);
            if (
              data.signIn.session.user &&
              data.signIn.session.user.role === "model"
            ) {
              window.localStorage.setItem(
                "user",
                JSON.stringify(data.signIn.session.user)
              );
              this.store.dispatch("onFetchUser", data.signIn.session.user);
              this.$emit("loged-in", false);
            } else {
              window.location.href = "/my";
            }
          })
          .catch(e => {
            this.errorHandler(e);
          });
      });
    },
    errorHandler(error) {
      window.localStorage.removeItem("lomoCastToken");
      this.showErrorPopup = true;
      if (error.signIn.errors) {
        this.errorMessage = `${error.signIn.errors[0].message}`;
        return;
      }
      this.errorMessage =
        "Something went wrong...\nPlease check email and password";
    }
  }
};
</script>

<style lang="scss" scoped>
.login-popup {
  .form {
    @media screen and (max-width: 767px) {
      padding-top: 32px;
    }
  }
  &__submit {
    margin-top: 68px;
    display: flex;
    justify-content: space-between;
    @media screen and (max-width: 767px) {
      margin-top: 32px;
      flex-direction: column;
    }
  }
  &__cancel {
    border: none;
    outline: none;
    background: none;
    text-transform: capitalize;
    height: 64px;
    min-width: 280px;
    padding: 0;
    display: inline-flex;
    align-items: center;
    justify-content: flex-start;
    @media screen and (max-width: 767px) {
      justify-content: center;
      margin-top: 18px;
      height: 48px;
      order: 2;
      min-width: 242px;
    }
  }
}
</style>
