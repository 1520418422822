<template>
  <div class="content-title" id="content-start">
    <div class="container content-title__container">
      <div class="row">
        <div class="col-3 col-md-2 col-sm-12"></div>
        <h2 class="content-title__title col-6 col-md-8 col-sm-12">
          {{ title }}
        </h2>
        <div class="col-3 col-md-2 col-sm-12"></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: String
  },
  setup() {
    return {};
  }
};
</script>

<style lang="scss" scoped>
@import "~@/assets/style/views/home/contentTitle.scss";
</style>
