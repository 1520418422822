<template>
  <div class="confirmation-page">
    <div class="container">
      <div class="confirmation">
        <h1 class="confirmation__title">
          Application received!
        </h1>
        <div class="confirmation__content">
          <div v-if="thankYouText" v-html="thankYouText"/>
          
          <template v-else>
            <p>
              Thank you, we have received your applications. We will now go
              through your application and get back to you.
            </p>
            <p>
              For questions, please contact
              <a href="mailto:info@lomocast.com">info@lomocast.com</a>
            </p>
          </template>
        </div>
        <div class="confirmation__close">
          <button class="btn" type="button" @click="closeClick">OK</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    confirmation: Boolean,
    thankYouText: String,
  },
  emits: ["update:confirmation"],
  methods: {
    closeClick() {
      this.$emit("update:confirmation", false);
    }
  }
};
</script>

<style lang="scss" scoped>
@import "~@/assets/style/views/jobs/confirmation.scss";
</style>
