<template>
  <div class="container">
    <div class="flex-title row">
      <h3 class="flex-title__title content-title col-6 col-lg-4 col-sm-12">
        {{ title }}
      </h3>
      <div class="flex-title__subtitle col-6 col-lg-8 col-sm-12">
        <div class="flex-title__subtitle-inner">
          <slot>{{ subtitle }}</slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {},
    subtitle: {}
  },
  setup() {
    return {};
  }
};
</script>

<style lang="scss" scoped>
@import "~@/assets/style/views/castings/flexTitle.scss";
</style>
