<template>
  <div v-show="featuredJobs.length" class="jobs">
    <div class="container jobs__container">
      <h3 class="jobs__title content-title">We’re currently looking for</h3>
      <div class="jobs__content row">
        <div class="col-3 col-md-2 col-sm-12"></div>
        <div class="col-6 col-md-8 col-sm-12">
          <router-link
            v-for="job in featuredJobs"
            :key="job.node.id"
            :to="`/jobs/${job.node.id}`"
            class="jobs__item jobs-item"
          >
            <h5 class="jobs-item__title">
              {{ job.node.title }}
            </h5>
            <div class="jobs-item__info">
              <span style="text-transform: capitalize;">
                <span
                  v-for="(type, index) in job.node.resourceTypes"
                  :key="index"
                >
                  <span>{{ type }}s</span>
                  <span
                    v-if="index === job.node.resourceTypes.length - 2"
                  > & </span>
                  <span
                    v-else-if="index < job.node.resourceTypes.length - 1"
                  >, </span>
                </span>.
              </span>
              <span v-if="job.node.until">
                Apply by
                {{ new Date(job.node.until).toISOString().slice(0, 10) }}
              </span>
            </div>
          </router-link>
          <router-link class="content-link jobs__link" to="/jobs">
            View all jobs
          </router-link>
        </div>
        <div class="col-3 col-md-2 col-sm-12"></div>
      </div>
    </div>
  </div>
</template>

<script>
import featuredJobsQuery from "../../graphql/featuredjobs.query.gql";
import { useQuery, useResult } from "@vue/apollo-composable";
export default {
  setup() {
    const variables = {
      resourceType: "all",
      order: "latest",
      featured: true,
      first: 5
    };
    const { result } = useQuery(featuredJobsQuery, variables);
    const featuredJobs = useResult(result, [], data => data.castings.edges);
    console.log(featuredJobs);
    return {
      featuredJobs
    };
  }
};
</script>

<style lang="scss" scoped>
@import "~@/assets/style/views/home/featuredJobs.scss";
</style>
