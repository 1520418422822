<template>
  <div class="custom-select" :tabindex="tabindex">
    <div
      ref="inputField"
      class="custom-select__selected"
      :class="{
        open: open,
        placeholder: selected == defaultValue,
        error: error
      }"
      @click="open = !open"
    >
      <input
        v-if="otherValue"
        v-model="selected"
        :placeholder="otherValueTrigger"
        type="text"
        @input="$emit('specify', selected)"
      />
      <span v-else>{{ selected }}</span>
    </div>
    <div v-if="open" class="custom-select__list " :class="{ error: error }">
      <div
        v-for="(option, index) in options"
        :key="index"
        class="custom-select__item"
        @click="selectHandle(option)"
      >
        {{ option }}
      </div>
    </div>
  </div>
</template>

<script>
// force files change
import { nextTick, reactive, ref } from "vue";
export default {
  props: {
    options: Array,
    tabindex: Number,
    defaultValue: String,
    error: Boolean
  },
  emits: ["input", "specify"],
  setup(props) {
    const selected = props.defaultValue
      ? props.defaultValue
      : props.options.length > 0
      ? reactive(props.options[0])
      : reactive(null);
    const open = ref(false);
    const otherValueTrigger = "Other (specify)";
    const otherValue = ref(false);
    return {
      selected,
      open,
      otherValueTrigger,
      otherValue
    };
  },
  methods: {
    selectHandle(option) {
      if (option === this.otherValueTrigger) {
        this.otherValue = true;
        this.selected = "";
        this.open = false;
        this.$emit("input", option);
        nextTick(() => {
          this.$refs.inputField.querySelector("input").focus();
        });
      } else {
        this.otherValue = false;
        this.selected = option;
        this.open = false;
        this.$emit("input", option);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import "~@/assets/style/views/jobs/custom-select.scss";
</style>
