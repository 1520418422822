<template>
  <div>
    <transition name="camillaLoader">
      <div v-if="!imagesReady" class="loader"></div>
    </transition>
    <transition name="fadetext">
      <div v-if="!imagesReady" class="loader-text">
        Loading <span>{{ Math.round(progress) }}%</span>
      </div>
    </transition>
    <Header />
    <Flex-title class="castings-page" :title="modelTitle">
      We cast people for the fashion, advertising and film industry. Below you
      can see a selection of all the talented people we represent. Want to see
      more? Please
      <router-link to="/contact">contact us</router-link>.
    </Flex-title>
    <div class="container">
      <Models-grid
        :models="newModels"
        v-model:progress="progress"
        v-model:imagesReady="imagesReady"
      />
    </div>
    <template v-if="portfolios.length">
      <Flex-title
        class="castings-page-spacer"
        :title="portfolioTitle"
        :subtitle="portfolioSubtitle"
      />
      <portfolios-grid />
    </template>
    <work-with-us v-if="isPageReady" :work="homePage.acf.work" />
    <Footer />
  </div>
</template>

<script>
import { ref, computed, onMounted } from "vue";
import { useStore } from "vuex";
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
import FlexTitle from "@/components/castings/FlexTitle.vue";
import ModelsGrid from "@/components/castings/ModelsGrid.vue";
import WorkWithUs from "@/components/home/Work.vue";
import PortfoliosGrid from "../components/castings/PortfoliosGrid.vue";
import FeaturedModelsQuery from "@/graphql/featuredModels.query.gql";
import { useQuery, useResult } from "@vue/apollo-composable";
export default {
  components: {
    Header,
    Footer,
    FlexTitle,
    ModelsGrid,
    WorkWithUs,
    PortfoliosGrid
  },
  setup() {
    const store = useStore();
    const isPageReady = computed(() => (homePage.value.acf ? true : false));
    const homePage = computed(() => store.state.pageHome);
    const modelTitle = ref("Humans");
    const portfolioTitle = ref("Portfolio");
    const portfolioSubtitle = ref(
      "And here is the result – a beautiful bunch of productions featuring a wide spectrum of different talents. Looking forward to seeing your film, ad, editorial or whatever vision you have in mind here soon."
    );
    const portfolios = computed(() =>
      store.state.portfolios.filter(el => el.acf.featured === true)
    );
    const { result } = useQuery(FeaturedModelsQuery, { first: 12 });
    const newModels = useResult(result, [], data => data.featuredModels.edges);
    const models = computed(() =>
      store.state.portfolios.filter(el => el.acf.featured === true)
    );
    const imagesReady = ref(false);
    const progress = ref(0);
    onMounted(() => {
      store.dispatch("onFetchHome");
      store.dispatch("onFetchPortfolios", { category: 1 });
    });
    return {
      modelTitle,
      portfolioTitle,
      portfolioSubtitle,
      portfolios,
      isPageReady,
      homePage,
      models,
      imagesReady,
      progress,
      newModels
    };
  }
};
</script>

<style lang="scss" scoped>
.castings-page-spacer {
  margin-top: 94px;
  @media screen and (max-width: 767px) {
    margin-top: 53px;
  }
}
</style>
