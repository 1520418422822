<template>
  <div class="portfolios">
    <div class="container portfolios__container">
      <h3 class="content-title portfolios__title">Portfolio</h3>
      <div class="portfolios__row row">
        <div
          v-for="(portfolio, index) in portfolios"
          :key="index"
          class="col-3 col-lg-4 col-md-6 col-sm-12 portfolios__col"
        >
          <router-link
            :to="`/portfolio/${portfolio.slug}`"
            class="portfolios__item portfolios-item"
          >
            <div class="portfolios-item__img">
              <img
                :srcset="portfolio.acf.thumbnail.srcset"
                :src="portfolio.acf.thumbnail.sizes.large"
                alt=""
              />
            </div>
            <div class="portfolios-item__title">
              {{ portfolio.title.rendered }}
            </div>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, onMounted } from "vue";
import { useStore } from "vuex";
export default {
  setup() {
    const store = useStore();
    const portfolios = computed(() =>
      store.state.portfolios.filter(el => el.acf.featured === true)
    );
    onMounted(() => {
      store.dispatch("onFetchPortfolios", { category: '' });
    });

    return {
      portfolios
    };
  }
};
</script>

<style lang="scss" scoped>
@import "~@/assets/style/views/home/portfolios.scss";
</style>
