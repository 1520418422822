<template>
  <div>
    <transition name="camillaLoader">
      <div v-if="!imagesReady" class="loader"></div>
    </transition>
    <transition name="fadetext">
      <div v-if="!imagesReady" class="loader-text">
        Loading <span>{{ Math.round(progress) }}%</span>
      </div>
    </transition>
    <Header v-model:white="headerState" v-model:footerState="footerState" />
    <div class="home-hero" :style="`height: ${height}`">
      <Hero
        v-if="isPageReady"
        :hero="homePage.acf.hero"
        v-model:progress="progress"
        v-model:imagesReady="imagesReady"
        @hoverEvent="hoverEvent"
      />
    </div>
    <Content-title v-if="isPageReady" :title="homePage.acf.contentTitle" />
    <We-cast v-if="isPageReady" :data="homePage.acf.weCast"></We-cast>
    <how-works v-if="isPageReady" :content="homePage.acf.howWorks"></how-works>
    <featured-jobs />
    <Portfolios />
    <work-with-us v-if="isPageReady" :work="homePage.acf.work"></work-with-us>
    <Footer :white="footerState" />
  </div>
</template>

<script>
import { ref, computed, onMounted } from "vue";
// @ is an alias to /src
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
import Hero from "@/components/home/Hero.vue";
import ContentTitle from "@/components/home/ContentTitle.vue";
import WeCast from "@/components/home/WeCast.vue";
import HowWorks from "@/components/home/HowWorks.vue";
import FeaturedJobs from "@/components/home/FeaturedJobs.vue";
import Portfolios from "@/components/home/Portfolios.vue";
import WorkWithUs from "@/components/home/Work.vue";
import { useStore } from "vuex";

export default {
  name: "Home",
  components: {
    Header,
    Footer,
    Hero,
    ContentTitle,
    WeCast,
    HowWorks,
    Portfolios,
    WorkWithUs,
    FeaturedJobs
  },
  setup() {
    const headerState = ref(false);
    const footerState = ref(false);
    const progress = ref(0);
    const imagesReady = ref(false);
    const isDesktop = window.innerWidth > 767 ? ref(true) : ref(false);
    const store = useStore();
    const isPageReady = computed(() => (homePage.value.acf ? true : false));
    const homePage = computed(() => store.state.pageHome);
    const height = ref("");
    onMounted(() => {
      store.dispatch("onFetchHome");
      if (!isDesktop.value) {
        height.value = `${window.innerHeight}px`;
      }
    });
    return {
      headerState,
      footerState,
      homePage,
      isPageReady,
      height,
      progress,
      imagesReady
    };
  },
  methods: {
    hoverEvent(event) {
      this.headerState = event;
      this.footerState = event;
    }
  }
};
</script>
<style lang="scss" scoped>
.home-hero {
  @media screen and (max-width: 767px) {
    overflow-y: auto;
  }
}
</style>
