<template>
  <h3
    class="form__subtitle flex-title"
    :style="Object.keys(currentUser).length ? 'margin-top: 0px;' : ''"
  >
    <span>
      Add
      <span v-for="(type, index) in modelTypes" :key="index">
        <span>{{ type }}</span>
        <span v-if="index === modelTypes.length - 2"> or </span>
        <span v-else-if="index < modelTypes.length - 1">, </span>
      </span>
    </span>
    <button
      v-if="index > 0"
      class="info remove-button"
      type="button"
      @click="$emit('delete', index)"
    >
      Remove
    </button>
  </h3>
  <div class="form__block-description">
    Please select human, animal or location, add details, and upload 8 to 10
    fresh photos.
  </div>
  <div>
    <div v-if="modelTypes.length > 1">
      <div
        v-for="(type, key) in modelTypes"
        :key="key"
        class="form-group radio-button"
      >
        <input
          v-model="model.modelType"
          type="radio"
          :name="`type${index}`"
          :id="`${type}${index}`"
          :value="type"
          @change="$emit('update:modelData', model)"
        />
        <label :for="`${type}${index}`">{{ type }}</label>
      </div>
    </div>
  </div>
  <human-model
    v-if="model.modelType === 'human' || model.modelType === 'model'"
    :submitClick="submitClick"
    v-model:model="model"
    @model-update="$emit('update:modelData', model)"
    @image-added="imageAdded"
    @image-uploaded="imageUploaded"
  />
  <animal-model
    v-else-if="model.modelType === 'animal'"
    :submitClick="submitClick"
    v-model:model="model"
    @model-update="$emit('update:modelData', model)"
    @image-added="imageAdded"
    @image-uploaded="imageUploaded"
  />
  <location-model
    v-else-if="model.modelType === 'location'"
    :submitClick="submitClick"
    v-model:model="model"
    @model-update="$emit('update:modelData', model)"
    @image-added="imageAdded"
    @image-uploaded="imageUploaded"
  />
  <div class="info" @click.prevent="$emit('showpopup', model.modelType)">
    What images to choose?
  </div>
</template>

<script>
import { computed, reactive } from "vue";
import HumanModel from "@/components/jobs/HumanModel.vue";
import AnimalModel from "@/components/jobs/AnimalModel.vue";
import LocationModel from "@/components/jobs/LocationModel.vue";
import { useStore } from "vuex";
export default {
  components: {
    HumanModel,
    AnimalModel,
    LocationModel
  },
  props: {
    modelData: Object,
    index: Number,
    submitClick: Boolean,
    modelTypes: {
      type: Array,
      default: () => ["human", "animal", "location"]
    }
  },
  emits: [
    "update:modelData",
    "showpopup",
    "delete",
    "image-added",
    "image-uploaded"
  ],
  setup(props, { emit }) {
    const store = useStore();
    const currentUser = computed(() => store.state.user);
    const model = reactive({
      modelType: props.modelTypes[0]
    });
    function imageAdded(value) {
      emit("image-added", value);
    }
    function imageUploaded(value) {
      emit("image-uploaded", value);
    }
    return {
      model,
      currentUser,
      imageAdded,
      imageUploaded
    };
  }
};
</script>
