<template>
  <div
    class="hero"
    :class="{ hovered: hovered }"
    :style="padding ? `padding: ${0.42 * padding}px 0 ${0.57 * padding}px` : ''"
  >
    <div class="hero__container container" ref="heroContent">
      <div class="hero__content">
        <h1 class="hero__title" @click="clickHandler">
          Home to
          <span style="display: inline-block">
            <router-link
              to="/locations"
              :data-bg="locationUrl"
              class="hover-container"
              @mouseenter="mouseEnterHandler"
              @click="mouseEnterHandler"
              @mouseleave="mouseLeaveHandler"
            >
              locations</router-link
            >,&thinsp;
          </span>
          <router-link
            to="/models"
            :data-bg="modelUrl"
            class="hover-container"
            @mouseenter="mouseEnterHandler"
            @click="mouseEnterHandler"
            @mouseleave="mouseLeaveHandler"
          >
            models</router-link
          >
          and <br v-if="isDesktop" />
          <span style="display: inline-block">
            <router-link
              to="/animals"
              :data-bg="animalUrl"
              class="hover-container"
              @mouseenter="mouseEnterHandler"
              @click="mouseEnterHandler"
              @mouseleave="mouseLeaveHandler"
            >
              animals</router-link
            >.
          </span>
          Some call it casting, <br v-if="isDesktop" />we call it Lomocast.
        </h1>
        <div
          v-if="hero && hero.text"
          class="hero__text"
          v-html="hero.text"
        ></div>
      </div>
      <div class="hero__btn">
        <a href="#content-start" class="btn btn--link" @click.prevent="scrollPageTo">
          Read more
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" width="14"><path fill="currentColor" d="M224 416c-8.188 0-16.38-3.125-22.62-9.375l-192-192c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0L224 338.8l169.4-169.4c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25l-192 192C240.4 412.9 232.2 416 224 416z"/></svg>
        </a>
      </div>
    </div>
    <div class="hero__bg" :class="{ hovered: hovered }" :style="bgImage"></div>
  </div>
</template>

<script>
import { ref } from "vue";
export default {
  props: {
    hero: {
      type: Object
    }
  },
  emits: ["hover-event", "update:progress", "update:imagesReady"],
  setup(props, { emit }) {
    const isDesktop = window.innerWidth > 767 ? ref(true) : ref(false);
    const hovered = ref(false);
    let bgImage = ref("background-color: #fff;");
    const bgDefault = bgImage.value;
    const progress = ref(0);
    const imagesReady = ref(false);
    const loadedImages = ref(0);
    const mouseEnterHandler = e => {
      hovered.value = true;
      bgImage.value = `background-image: url("${e.target.dataset.bg}")`;
      emit("hover-event", true);
    };
    const mouseLeaveHandler = () => {
      hovered.value = false;
      emit("hover-event", false);
      if (bgDefault) {
        bgImage.value = bgDefault;
      } else {
        bgImage.value = "";
      }
    };
    const clickHandler = e => {
      if (e.target.classList.contains("hover-container")) return;
      hovered.value = false;
      emit("hover-event", false);
      if (bgDefault) {
        bgImage.value = bgDefault;
      } else {
        bgImage.value = "";
      }
    };
    const scrollPageTo = e => {
      let element = document.getElementById(
        `${e.target
          .closest("a")
          .getAttribute("href")
          .slice(1)}`
      );
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    };
    const locationUrl = ref("");
    const modelUrl = ref("");
    const animalUrl = ref("");
    const padding = ref("");
    const preloadImage = imageUrl => {
      return new Promise((resolve, reject) => {
        const image = new Image();
        image.onload = () => {
          resolve(imageUrl);
        };
        image.onerror = er => reject(er);
        image.src = imageUrl;
      });
    };

    return {
      mouseLeaveHandler,
      mouseEnterHandler,
      clickHandler,
      bgImage,
      scrollPageTo,
      isDesktop,
      padding,
      hovered,
      locationUrl,
      modelUrl,
      animalUrl,
      preloadImage,
      progress,
      loadedImages,
      imagesReady
    };
  },
  mounted() {
    if (!this.isDesktop) {
      this.padding = window.innerHeight - this.$refs.heroContent.clientHeight;
      this.padding = 0.42 * this.padding > 68 ? this.padding : "";
      this.preloadImage(this.hero.locations.mobileImage.url).then(res => {
        this.locationUrl = res;
        this.loadedImages++;
        this.progress = (this.loadedImages / 3) * 100;
        this.$emit("update:progress", this.progress);
        this.$nextTick(() => {
          if (this.loadedImages >= 3) {
            this.imagesReady = true;
            this.$emit("update:imagesReady", this.imagesReady);
          }
        });
      });
      this.preloadImage(this.hero.models.mobileImage.url).then(res => {
        this.modelUrl = res;
        this.loadedImages++;
        this.progress = (this.loadedImages / 3) * 100;
        this.$emit("update:progress", this.progress);
        this.$nextTick(() => {
          if (this.loadedImages >= 3) {
            this.imagesReady = true;
            this.$emit("update:imagesReady", this.imagesReady);
          }
        });
      });
      this.preloadImage(this.hero.animals.mobileImage.url).then(res => {
        this.animalUrl = res;
        this.loadedImages++;
        this.progress = (this.loadedImages / 3) * 100;
        this.$emit("update:progress", this.progress);
        this.$nextTick(() => {
          if (this.loadedImages >= 3) {
            this.imagesReady = true;
            this.$emit("update:imagesReady", this.imagesReady);
          }
        });
      });
    } else {
      this.preloadImage(this.hero.locations.image.url).then(res => {
        this.locationUrl = res;
        this.loadedImages++;
        this.progress = (this.loadedImages / 3) * 100;
        this.$emit("update:progress", this.progress);
        this.$nextTick(() => {
          if (this.loadedImages >= 3) {
            this.imagesReady = true;
            this.$emit("update:imagesReady", this.imagesReady);
          }
        });
      });
      this.preloadImage(this.hero.models.image.url).then(res => {
        this.modelUrl = res;
        this.loadedImages++;
        this.progress = (this.loadedImages / 3) * 100;
        this.$emit("update:progress", this.progress);
        this.$nextTick(() => {
          if (this.loadedImages >= 3) {
            this.imagesReady = true;
            this.$emit("update:imagesReady", this.imagesReady);
          }
        });
      });
      this.preloadImage(this.hero.animals.image.url).then(res => {
        this.animalUrl = res;
        this.loadedImages++;
        this.progress = (this.loadedImages / 3) * 100;
        this.$emit("update:progress", this.progress);
        this.$nextTick(() => {
          if (this.loadedImages >= 3) {
            this.imagesReady = true;
            this.$emit("update:imagesReady", this.imagesReady);
          }
        });
      });
    }
  }
};
</script>

<style lang="scss" scoped>
@import "~@/assets/style/views/home/hero.scss";
</style>
