<template>
  <div class="login">
    <Header />
    <flex-title :title="loginTitle" :subtitle="loginSubtitle" />
    <div class="login__container">
      <form class="form" id="form">
        <div class="form__container container">
          <div class="form-group" :class="{ error: v.password.$errors.length }">
            <label class="form-label">Password</label>
            <input
              v-model="password"
              class="form-input"
              type="password"
              placeholder="Enter password"
            />
            <p
              v-for="(error, index) of v.password.$errors"
              :key="index"
              class="error-message"
            >
              {{ error.$message }}
            </p>
          </div>
          <div class="form-group" :class="{ error: v.passwordConfirmation.$errors.length }">
            <label class="form-label">Password confirmation</label>
            <input
              v-model="passwordConfirmation"
              class="form-input"
              type="password"
              placeholder="Enter Password again"
            />
            <p
              v-for="(error, index) of v.passwordConfirmation.$errors"
              :key="index"
              class="error-message"
            >
              {{ error.$message }}
            </p>
          </div>
          <div class="form__submit">
            <div class="info">
              <a href='/login'> Login </a>
            </div>
            <button
              :disabled="v.$invalid"
              class="btn"
              type="submit"
              @click.prevent="submitHandler"
            >
              Submit
            </button>
            <!-- <button v-if="v.$invalid" disabled class="btn" type="submit">
              submit
            </button> -->
          </div>
        </div>
      </form>
    </div>
    <Footer
      v-if="isPageReady"
      :legalText="homePage.acf.legalText"
      :contactText="homePage.acf.contactText"
    />
    <teleport to="[data-error-popup]">
      <error-popup
        v-model:showPopup="showErrorPopup"
        :errorMessage="errorMessage"
        :white="true"
      />
    </teleport>
  </div>
</template>

<script>
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
import FlexTitle from "@/components/castings/FlexTitle.vue";
import useVuelidate from "@vuelidate/core";
import { required, helpers } from "@vuelidate/validators";
import { computed, nextTick, onMounted, ref } from "vue";
import { useMutation } from "@vue/apollo-composable";
import resetPasswordMutation from "@/graphql/resetPassword.mutation.gql";
import ErrorPopup from "@/components/ErrorPopup.vue";
import { useStore } from 'vuex';

export default {
  components: {
    Header,
    Footer,
    FlexTitle,
    ErrorPopup
  },
  setup () {
    const loginTitle = 'Reset password';
    const loginSubtitle = 'Please choose a new password for your account below'
    const password = ref("");
    const passwordConfirmation = ref("");
    const passwordErrorMessage = "Password field cannot be empty";
    const passwordConfirmationErrorMessage = "Password confirmation field cannot be empty";
    const passwordConfirmationSameErrorMessage = "Password confirmation should be the same as password";
    const politic = ref(false);
    const showErrorPopup = ref(false);
    const errorMessage = ref("");
    const store = useStore();
    const isPageReady = computed(() => homePage.value.acf ? true : false);
    const homePage = computed(() => store.state.pageHome);

    onMounted(() => {
      store.dispatch("onFetchHome");
    });

    const { mutate: resetPassword } = useMutation(resetPasswordMutation);

    const rules = {
      password: {
        required: helpers.withMessage(passwordErrorMessage, required),
        containsPasswordRequirement: helpers.withMessage(
          () => `The password requires an uppercase, lowercase, number and a special character`,
          (value) => /(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#?$%^&*-])/.test(value)
        ),
        $autoDirty: true
      },
      passwordConfirmation: {
        required: helpers.withMessage(passwordConfirmationErrorMessage, required),
        sameAsPassword: helpers.withMessage(passwordConfirmationSameErrorMessage, value =>
          password.value === value
        ),
        $autoDirty: true
      }
    };
    const v = useVuelidate(rules, {
      password,
      passwordConfirmation
    });
    return {
      loginTitle,
      loginSubtitle,
      password,
      passwordConfirmation,
      politic,
      resetPassword,
      showErrorPopup,
      errorMessage,
      homePage,
      isPageReady,
      v
    };
  },
  methods: {
    submitHandler() {
      this.v.$touch();
      if (this.v.$error) {
        return;
      }

      const resetPasswordToken = this.$route.query.reset_password_token;

      if (!resetPasswordToken) {
        this.errorHandler('This link is not valid or has expired. Please request a new password using the "forgot password" function and follow the link in the email. If you still are having problems, please contact support.')
        return;
      }

      nextTick(() => {
        this.resetPassword({
          input: {
            password: this.password,
            passwordConfirmation: this.passwordConfirmation,
            resetPasswordToken
          }
        })
          .then(({ data }) => {
            if (!data.resetPassword.user) {
              this.errorHandler('Something went wrong');
              return;
            }
            this.errorHandler('Password reset succesfully. Use new password to sing in.')
          })
          .catch(e => {
            const message = e.networkError?.result?.errors?.map(error => error.message)?.join(', ') || e.message;
            this.errorHandler(message);
          });
      });
    },
    errorHandler(error) {
      this.showErrorPopup = true;
      this.errorMessage = error;
    }
  }
};
</script>

<style lang="scss" scoped>
@import "~@/assets/style/views/login/login.scss";
</style>
