<template>
  <div class="not-found">
    <h1>404</h1>
    <h2>Page not found</h2>
    <router-link to="/" class="btn btn--primary"> Home </router-link>
  </div>
</template>

<style lang="scss" scoped>
.not-found {
  text-align: center;
  padding: 100px 24px;
  h1 {
    font-size: 90px;
    font-weight: normal;
  }
  h2 {
    font-size: 30px;
    font-weight: normal;
    margin: 10px 0 30px;
  }
}
</style>