<template>
  <div class="about-page">
    <transition name="camillaLoader">
      <div v-if="!isPageReady || !imagesReady" class="loader"></div>
    </transition>
    <transition name="fadetext" @before-leave="beforeLeave">
      <div v-if="!isPageReady || !imagesReady" class="loader-text">
        Loading <span>{{ Math.round(progress) }}%</span>
      </div>
    </transition>
    <Header />
    <div
      v-if="isPageReady && imagesReady"
      class="about-page__container container"
    >
      <h2 class="about-page__title">{{ aboutPage.acf.title }}</h2>
      <div class="about-page__text" v-html="aboutPage.acf.text"></div>
    </div>
    <Footer />
  </div>
</template>

<script>
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
import { computed, onMounted, ref } from "vue";
import { useStore } from "vuex";
export default {
  components: {
    Header,
    Footer
  },
  setup() {
    const store = useStore();
    const isPageReady = computed(() => (aboutPage.value.acf ? true : false));
    const aboutPage = computed(() => store.state.pageAbout);
    const progress = computed(() => (aboutPage.value.acf ? 100 : 0));
    const beforeLeave = () => {
      progress.value = 100;
    };
    const imagesReady = ref(false);
    onMounted(() => {
      store.dispatch("onFetchAbout");
      setTimeout(() => {
        imagesReady.value = true;
      }, 200);
    });
    return {
      progress,
      isPageReady,
      aboutPage,
      beforeLeave,
      imagesReady
    };
  }
};
</script>

<style lang="scss" scoped>
@import "~@/assets/style/views/about/about.scss";
</style>
