<template>
  <div v-if="modelType" class="popup white">
    <div class="popup__wrap">
      <button type="button" class="popup__close" @click="hidePopup">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="15.556"
          height="15.556"
          viewBox="0 0 15.556 15.556"
        >
          <rect
            width="3"
            height="19"
            transform="translate(0 2.121) rotate(-45)"
          />
          <rect
            width="3"
            height="19"
            transform="translate(2.121 15.556) rotate(-135)"
          />
        </svg>
      </button>
      <div class="popup__container">
        <div class="popup__content">
          <h3 class="popup__title">What images to choose?</h3>
          <div v-if="modelType === 'human'" class="popup__text">
            {{ humanText }}
          </div>
          <div v-if="modelType === 'animal'" class="popup__text">
            {{ animalsText }}
          </div>
          <div v-if="modelType === 'location'" class="popup__text">
            {{ locationsText }}
          </div>
        </div>
        <div class="popup__confirmation">
          <button class="btn" type="button" @click="hidePopup">
            OK
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed } from "vue";
export default {
  props: {
    humanText: String,
    animalsText: String,
    locationsText: String,
    modelType: String,
    showPopup: Boolean
  },
  emits: ["update:showPopup"],
  setup(props, { emit }) {
    const popupStatus = computed({
      get: () => props.showPopup,
      set: value => emit("update:showPopup", value)
    });
    return {
      popupStatus
    };
  },
  methods: {
    hidePopup() {
      this.popupStatus = false;
      document.body.classList.remove("overflow");
    }
  }
};
</script>

<style lang="scss" scoped>
@import "~@/assets/style/components/popup.scss";
</style>
