<template>
  <div class="login">
    <Header />
    <flex-title :title="loginTitle" :subtitle="loginSubtitle" />
    <div class="login__container">
      <form class="form" id="form">
        <div class="form__container container">
          <div class="form-group" :class="{ error: v.emailAddress.$errors.length }">
            <label class="form-label">E-mail</label>
            <input
              v-model="emailAddress"
              class="form-input"
              type="text"
              placeholder="Enter e-mail"
            />
            <p
              v-for="(error, index) of v.emailAddress.$errors"
              :key="index"
              class="error-message"
            >
              {{ error.$message }}
            </p>
          </div>
          <div class="form__submit">
            <div class="info">
              <a href='/login'> Login </a>
            </div>
            <button
              :disabled="v.$invalid"
              class="btn"
              type="submit"
              @click.prevent="submitHandler"
            >
              submit
            </button>
            <!-- <button v-if="v.$invalid" disabled class="btn" type="submit">
              submit
            </button> -->
          </div>
        </div>
      </form>
    </div>
    <Footer 
      v-if="isPageReady"
      :legalText="homePage.acf.legalText"
      :contactText="homePage.acf.contactText"
    />
    <teleport to="[data-error-popup]">
      <error-popup
        v-model:showPopup="showErrorPopup"
        :errorMessage="errorMessage"
        :white="true"
      />
    </teleport>
  </div>
</template>

<script>
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
import FlexTitle from "@/components/castings/FlexTitle.vue";
import useVuelidate from "@vuelidate/core";
import { email, helpers } from "@vuelidate/validators";
import { computed, nextTick, onMounted, ref } from "vue";
import { useMutation } from "@vue/apollo-composable";
import requestResetPasswordMutation from "@/graphql/requestResetPassword.mutation.gql";
import ErrorPopup from "@/components/ErrorPopup.vue";
import { useStore } from 'vuex';

export default {
  components: {
    Header,
    Footer,
    FlexTitle,
    ErrorPopup
  },
  setup () {
    const loginTitle = 'Forgot password';
    const loginSubtitle = 'Fill in your e-mail address below to receive an e-mail with instructions how to reset your password'
    const emailAddress = ref("");
    const emailRequiredErrorMessage = "Email field cannot be empty";
    const emailErrorMessage = "Email not valid";
    const politic = ref(false);
    const showErrorPopup = ref(false);
    const errorMessage = ref("");
    const store = useStore();
    const isPageReady = computed(() => homePage.value.acf ? true : false);
    const homePage = computed(() => store.state.pageHome);

    onMounted(() => {
      store.dispatch("onFetchHome");
    });

    const { mutate: requestResetPassword } = useMutation(requestResetPasswordMutation);

    const rules = {
      emailAddress: {
        required: helpers.withMessage(emailRequiredErrorMessage, value =>
          value ? true : false
        ),
        email: helpers.withMessage(emailErrorMessage, email),
        $autoDirty: true
      }
    };
    const v = useVuelidate(rules, {
      emailAddress
    });
    return {
      loginTitle,
      loginSubtitle,
      emailAddress,
      politic,
      requestResetPassword,
      showErrorPopup,
      errorMessage,
      homePage,
      isPageReady,
      v
    };
  },
  methods: {
    submitHandler() {
      this.v.$touch();
      if (this.v.$error) {
        return;
      }
      nextTick(() => {
        this.requestResetPassword({
          input: {
            email: this.emailAddress,
            redirectUrl: `${process.env.VUE_APP_DOMAIN}/reset-password`
          }
        })
          .then(({ data }) => {
            if (!data.requestResetPassword.passwordReset) {
              this.errorHandler('Something went wrong');
              return;
            }
            this.errorHandler(data.requestResetPassword.passwordReset);
          })
          .catch(e => {
            const message = e.networkError?.result?.errors?.map(error => error.message)?.join(', ') || e.message;
            this.errorHandler(message);
          });
      });
    },
    errorHandler(error) {
      this.showErrorPopup = true;
      this.errorMessage = error;
    }
  }
};
</script>

<style lang="scss" scoped>
@import "~@/assets/style/views/login/login.scss";
</style>
