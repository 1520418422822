<template>
  <div class="job-page">
    <transition name="camillaLoader">
      <div v-if="!imagesReady || firstLoading" class="loader"></div>
    </transition>
    <transition name="fadetext" @before-leave="beforeLeave">
      <div v-if="!imagesReady || firstLoading" class="loader-text">
        Loading <span>{{ Math.round(progress) }}%</span>
      </div>
    </transition>
    <transition name="fade">
      <div v-if="sendingData" class="loader"></div>
    </transition>
    <transition name="fade">
      <div v-if="sendingData" class="loader-progress">
        <div
          class="loader-progress__info"
          :style="addedImages ? '' : 'justify-content:center;'"
        >
          <label>Transferring, please wait</label>
          <label v-if="addedImages">
            {{ Math.round((100 * uploadedImages) / addedImages) }}%
          </label>
        </div>
        <div v-if="addedImages" class="loader-progress__background"></div>
        <transition name="fade">
          <div
            v-if="addedImages"
            class="loader-progress__tracker"
            :style="`width: ${(100 * uploadedImages) / addedImages}%`"
          ></div>
        </transition>
      </div>
    </transition>
    <Header />
    <div v-if="job" class="container">
      <div class="row">
        <div class="col-3 col-sm-12">
        </div>
        <div class="col-6 col-sm-12">
          <div class="job-page__title content-title">{{ job.name }}</div>
          <div class="job-page__subtitle">
            <span style="text-transform: capitalize;">
              <span
                v-for="(type, index) in job.resourceTypes"
                :key="index"
              >
                {{ type }}s<span
                  v-if="index === job.resourceTypes.length - 2"
                > & </span>
                <span
                  v-else-if="index < job.resourceTypes.length - 1"
                >, </span>
              </span>.
            </span>
            <span v-if="job.until">
              Apply by
              {{ new Date(job.until).toISOString().slice(0, 10) }}
            </span>
          </div>
          <div
            v-if="job.introText && job.running"
            class="job-page__description"
            v-html="job.introText.replaceAll('\n', '<br />')"
          ></div>
          <div
            v-if="job.disabledText && !job.running"
            class="job-page__description"
            v-html="job.disabledText.replaceAll('\n', '<br />')"
          ></div>
          <div
            v-if="appliedResources.length"
            class="job-page__applied applied-block"
          >
            <div class="applied-block__title">
              <span>You have applied to this casting with</span>&nbsp;
              <span v-for="(item, index) in appliedResources" :key="index">
                <a
                  v-if="item.resourceType === 'model'"
                  :href="`${userFrontendBaseUrl}/account/resources/${item.id}/`"
                >
                  {{ item.resourceModel.firstName }}
                </a>
                <a
                  v-if="item.resourceType === 'animal'"
                  :href="`${userFrontendBaseUrl}/account/resources/${item.id}/`"
                >
                  {{ item.resourceAnimal.name }}
                </a>
                <a
                  v-if="item.resourceType === 'location'"
                  :href="`${userFrontendBaseUrl}/account/resources/${item.id}/`"
                >
                  {{ item.resourceLocation.name }}
                </a>
                <span v-if="index === appliedResources.length - 2"> and </span>
                <span v-else-if="index < appliedResources.length - 1">, </span>
              </span>
            </div>
            <div class="applied-block__text">
              <a :href="`${userFrontendBaseUrl}`">Check your profile page</a> to
              sure the details are up to date and the images are recent. Press
              the button below to cancel or modify application.
            </div>
          </div>
          <template v-if="job.running">
            <div
              v-if="!Object.keys(currentUser).length"
              class="job-page__actions row"
            >
              <div class="col-6 col-lg-12 job-action">
                <div class="job-action__title">Not a member?<br />Apply here</div>
                <div class="job-action__btn">
                  <button class="btn" @click="showPopup = true">Apply</button>
                </div>
              </div>
              <div class="col-6 col-lg-12 job-action">
                <div class="job-action__title">
                  Already a member?<br />Login to apply
                </div>
                <div class="job-action__btn">
                  <button class="btn" @click="login = true">Login</button>
                </div>
              </div>
            </div>
            <div v-else-if="!appliedResources.length" class="job-page__actions">
              <div class="job-action">
                <div class="job-action__btn">
                  <button class="btn" @click="addModels = true">Apply</button>
                </div>
              </div>
            </div>
            <div v-else class="job-page__actions">
              <div class="job-action">
                <div class="job-action__btn">
                  <button class="btn secondary" @click="modify = true">
                    Modify application
                  </button>
                </div>
              </div>
            </div>
          </template>
          <div class="job-page__contact">
            For questions, please contact us at
            <a href="mailto:info@lomocast.com">info@lomocast.com</a><br />
            To apply is free of charge.
          </div>
        </div>
        <div class="col-3 col-sm-12">
          <button class="job-page__back" @click="goBack">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="15.556"
              height="15.556"
              viewBox="0 0 15.556 15.556"
            >
              <rect
                width="3"
                height="19"
                transform="translate(0 2.121) rotate(-45)"
              />
              <rect
                width="3"
                height="19"
                transform="translate(2.121 15.556) rotate(-135)"
              />
            </svg>
          </button>
        </div>
      </div>
    </div>
    <Footer />
    <teleport to="[data-popup]">
      <div v-if="showPopup" class="job-popup">
        <div class="job-popup__wrap">
          <button
            type="button"
            class="popup__close"
            @click="showPopup = !showPopup"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="15.556"
              height="15.556"
              viewBox="0 0 15.556 15.556"
            >
              <rect
                width="3"
                height="19"
                transform="translate(0 2.121) rotate(-45)"
              />
              <rect
                width="3"
                height="19"
                transform="translate(2.121 15.556) rotate(-135)"
              />
            </svg>
          </button>
          <div class="job-popup__container">
            <apply-form
              :modelTypes="job.resourceTypes"
              :castingID="job.id"
              v-model:confirmation="confirmation"
              v-model:showPopup="showPopup"
              @added-new="refetchResources"
              :dataReady="dataReady"
              @sending="sendingHandler"
              @image-added="imageAdded"
              @image-uploaded="imageUploaded"
            />
          </div>
        </div>
      </div>
    </teleport>
    <teleport to="[data-popup]">
      <div v-if="login" class="job-popup">
        <div class="job-popup__wrap">
          <button type="button" class="popup__close" @click="login = !login">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="15.556"
              height="15.556"
              viewBox="0 0 15.556 15.556"
            >
              <rect
                width="3"
                height="19"
                transform="translate(0 2.121) rotate(-45)"
              />
              <rect
                width="3"
                height="19"
                transform="translate(2.121 15.556) rotate(-135)"
              />
            </svg>
          </button>
          <div class="job-popup__container">
            <popup-login @loged-in="logedIn" />
          </div>
        </div>
      </div>
    </teleport>
    <teleport to="[data-popup]">
      <div v-if="addModels" class="job-popup">
        <div class="job-popup__wrap">
          <button
            type="button"
            class="popup__close"
            @click="addModels = !addModels"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="15.556"
              height="15.556"
              viewBox="0 0 15.556 15.556"
            >
              <rect
                width="3"
                height="19"
                transform="translate(0 2.121) rotate(-45)"
              />
              <rect
                width="3"
                height="19"
                transform="translate(2.121 15.556) rotate(-135)"
              />
            </svg>
          </button>
          <div class="job-popup__container">
            <apply-models-popup
              :modelTypes="job.resourceTypes"
              :castingID="job.id"
              v-model:addModels="addModels"
              @add-new="this.showPopup = true"
              @resources-added="resourcesAdded"
            />
          </div>
        </div>
      </div>
    </teleport>
    <teleport to="[data-popup]">
      <div v-if="modify" class="job-popup">
        <div class="job-popup__wrap">
          <button type="button" class="popup__close" @click="modify = !modify">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="15.556"
              height="15.556"
              viewBox="0 0 15.556 15.556"
            >
              <rect
                width="3"
                height="19"
                transform="translate(0 2.121) rotate(-45)"
              />
              <rect
                width="3"
                height="19"
                transform="translate(2.121 15.556) rotate(-135)"
              />
            </svg>
          </button>
          <div class="job-popup__container">
            <modify-popup
              :modelTypes="job.resourceTypes"
              :appliedModels="addedModels"
              :castingID="job.id"
              v-model:modify="modify"
              v-model:showPopup="showPopup"
              @casting-modified="resourcesAdded"
            />
          </div>
        </div>
      </div>
    </teleport>
    <teleport to="[data-error-popup]">
      <div v-if="confirmation" class="job-popup">
        <div class="job-popup__wrap">
          <button
            type="button"
            class="popup__close"
            @click="confirmation = !confirmation"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="15.556"
              height="15.556"
              viewBox="0 0 15.556 15.556"
            >
              <rect
                width="3"
                height="19"
                transform="translate(0 2.121) rotate(-45)"
              />
              <rect
                width="3"
                height="19"
                transform="translate(2.121 15.556) rotate(-135)"
              />
            </svg>
          </button>
          <div class="job-popup__container">
            <confirmation-popup v-model:confirmation="confirmation" :thankYouText="job.thankYouText.replaceAll('\n', '<br />')"/>
          </div>
        </div>
      </div>
    </teleport>
  </div>
</template>

<script>
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
import ApplyForm from "@/components/jobs/ApplyForm.vue";
import PopupLogin from "@/components/jobs/PopupLogin.vue";
import ApplyModelsPopup from "@/components/jobs/ApplyModelsPopup.vue";
import ModifyPopup from "@/components/jobs/ModifyPopup.vue";
import ConfirmationPopup from "@/components/jobs/confirmationPopup.vue";
import { computed, onMounted, reactive, ref } from "vue";

import jobQuery from "@/graphql/job.query.gql";
import castingAppliedResources from "@/graphql/castingAppliedResources.query.gql";
import { useQuery, useResult } from "@vue/apollo-composable";
import { useStore } from "vuex";
export default {
  props: {
    id: [String, Number],
    presetJob: Object,
  },
  components: {
    Header,
    Footer,
    ApplyForm,
    PopupLogin,
    ApplyModelsPopup,
    ModifyPopup,
    ConfirmationPopup
  },
  setup(props) {
    const store = useStore();
    const currentUser = computed(() => store.state.user);
    const progress = ref(0);
    const firstLoading = ref(true);
    const imagesReady = ref(false);
    const showPopup = ref(false);
    const login = ref(false);
    const addModels = ref(false);
    const modify = ref(false);
    const dataReady = ref(false);
    const sendingData = ref(false);
    const addedImages = ref(0);
    const uploadedImages = ref(0);
    const userFrontendBaseUrl = process.env.VUE_APP_USER_FRONTEND_BASE_URL;
    const formatDate = date => {
      const formatter = new Intl.DateTimeFormat("en-US", {
        month: "long",
        day: "2-digit",
        year: "numeric"
      });
      return formatter.format(date);
    };
    const beforeLeave = () => {
      progress.value = 100;
    };
    const variables = reactive({
      id: props.id,
    });


    // const { result, loading } = useQuery(jobQuery, variables, {
    //   fetchPolicy: "cache-and-network"
    // });
    // const job = useResult(result, [], data => data.casting);


    let job, loading;
    if (props.presetJob) {
      job = computed(() => props.presetJob);
      loading = false;
      progress.value = 100;
      firstLoading.value = false;
    } else {
      const { result, loading: tmpLoading } = useQuery(jobQuery, variables, {
        fetchPolicy: "cache-and-network",
      });
      loading = tmpLoading;
      job = useResult(result, [], (data) => data.casting);
    }

    const jobUntil = computed(() => (job.until ? job.until : job.createdAt));

    const variablesCastingQuery = reactive({
      castingId: +props.id
    });

    const confirmation = ref(false);
    onMounted(() => {
      setTimeout(() => {
        progress.value = 100;
        imagesReady.value = true;
      }, 200);
    });
    const appliedResult = useQuery(
      castingAppliedResources,
      variablesCastingQuery
    );
    const refetchResources = appliedResult.refetch;
    const appliedResources = useResult(
      appliedResult.result,
      [],
      data => data.user.userModel.resources
    );
    const addedModels = computed(() => appliedResources.value.map(el => el.id));
    return {
      progress,
      beforeLeave,
      job,
      jobUntil,
      loading,
      firstLoading,
      imagesReady,
      formatDate,
      showPopup,
      login,
      addModels,
      currentUser,
      appliedResources,
      addedModels,
      modify,
      refetchResources,
      confirmation,
      userFrontendBaseUrl,
      sendingData,
      dataReady,
      addedImages,
      uploadedImages
    };
  },
  watch: {
    loading() {
      if (this.loading === false) {
        this.firstLoading = false;
      }
    }
  },
  methods: {
    goBack() {
      if (!window.history.state.back) {
        this.$router.push("/jobs");
      } else {
        this.$router.go(-1);
      }
    },
    logedIn(event) {
      this.login = event;
      this.refetchResources();
    },
    resourcesAdded() {
      this.confirmation = true;
      this.refetchResources();
    },
    sendingHandler(event) {
      this.sendingData = event;
      if (this.sendingData && !this.addedImages) {
        this.dataReady = true;
      }
    },
    imageAdded(value) {
      this.addedImages += value;
      if (this.uploadedImages !== this.addedImages) {
        this.dataReady = false;
      }
    },
    imageUploaded(value) {
      this.uploadedImages += value;
      if (this.uploadedImages === this.addedImages) {
        this.$nextTick(() => {
          setTimeout(() => {
            this.dataReady = true;
          }, 300);
        });
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import "~@/assets/style/views/jobs/job.scss";
</style>
