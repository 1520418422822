<template>
  <form class="form" id="form">
    <div class="form__container container">
      <div v-if="!Object.keys(currentUser).length">
        <h2 class="form__title">Apply to Lomocast!</h2>
        <div class="form__description">
          <p>
            Please fill in your contact details in the form below and choose if you're a human, an animal or a location. Upload a few fresh photos, submit and voilà - you're done.
          </p>
          <p>
            If you have any questions, please contact
            <a href="mailto:info@lomocast.com">info@lomocast.com</a>
          </p>
        </div>
        <h3 class="form__subtitle">Enter your contact details</h3>
        <div class="form__block-description">
          Please enter your name and email if you want to apply or if you're the
          contact person for a child, an animal or a location.
        </div>
        <div class="form-group" :class="{ error: v.name.$errors.length }">
          <label class="form-label">First name</label>
          <input
            v-model="name"
            class="form-input"
            type="text"
            placeholder="Enter first name of contact person"
          />
          <p
            v-for="(error, index) of v.name.$errors"
            :key="index"
            class="error-message"
          >
            {{ error.$message }}
          </p>
        </div>
        <div class="form-group" :class="{ error: v.lastName.$errors.length }">
          <label class="form-label">Last name</label>
          <input
            v-model="lastName"
            class="form-input"
            type="text"
            placeholder="Enter last name of contact person"
          />
          <p
            v-for="(error, index) of v.lastName.$errors"
            :key="index"
            class="error-message"
          >
            {{ error.$message }}
          </p>
        </div>
        <div
          class="form-group"
          :class="{ error: v.emailAddress.$errors.length }"
        >
          <label class="form-label">E-mail</label>
          <input
            v-model="emailAddress"
            class="form-input"
            type="text"
            placeholder="Enter e-mail of contact person"
          />
          <p
            v-for="(error, index) of v.emailAddress.$errors"
            :key="index"
            class="error-message"
          >
            {{ error.$message }}
          </p>
        </div>

        <div class="row">
          <div class="col-2 col-sm-4">
            <div
              class="form-group"
              :class="{ error: v.countryCode.$errors.length }"
            >
              <label class="form-label">Country code</label>
              <input
                v-model="countryCode"
                class="form-input"
                type="text"
                placeholder="E.g. 46"
              />
              <p
                v-for="(error, index) of v.countryCode.$errors"
                :key="index"
                class="error-message"
              >
                {{ error.$message }}
              </p>
            </div>
          </div>
          <div class="col-10 col-sm-8">
            <div class="form-group" :class="{ error: v.phone.$errors.length }">
              <label class="form-label">Area code and phone number</label>
              <input
                v-model="phone"
                class="form-input"
                type="text"
                placeholder="E.g. 0101231234"
              />
              <p
                v-for="(error, index) of v.phone.$errors"
                :key="index"
                class="error-message"
              >
                {{ error.$message }}
              </p>
            </div>
          </div>
        </div>
      </div>

      <div v-for="(model, index) in models" :key="index">
        <model-form
          v-model:modelData="models[index]"
          :index="index"
          :submitClick="submitClick"
          :modelTypes="modelTypes"
          @showpopup="showPopupHandler"
          @delete="deleteItem"
          @image-added="imageAdded"
          @image-uploaded="imageUploaded"
        />
      </div>
      <h3 class="form__subtitle">
        Add another
        <span v-for="(type, index) in modelTypes" :key="index">
          <span>{{ type }}</span>
          <span v-if="index === modelTypes.length - 2"> or </span>
          <span v-else-if="index < modelTypes.length - 1">, </span>
        </span>
      </h3>
      <div class="form__block-description">
        If you want to apply with another human, animal or location, please
        click “Add another” below and follow the instructions.
      </div>
      <div class="info" @click="addAnotherHandler">
        + Add another
        <span v-for="(type, index) in modelTypes" :key="index">
          <span>{{ type }}</span>
          <span v-if="index === modelTypes.length - 2"> or </span>
          <span v-else-if="index < modelTypes.length - 1">, </span>
        </span>
      </div>
      <h3 v-if="!Object.keys(currentUser).length" class="form__subtitle">
        Submit your application
      </h3>
      <div
        v-if="!Object.keys(currentUser).length"
        class="form-group"
        :class="{ error: v.politic.$errors.length }"
      >
        <label class="checkbox">
          <input
            v-model="politic"
            class="checkbox__input"
            type="checkbox"
            name="politic"
          />
          <div class="checkbox__icon"></div>
          <div class="checkbox__label">
            I agree to allow LomoCast to store and process my personal data.
            <a
              class="info"
              href="/legal"
              @click.prevent="showLegalPopup = true"
            >
              Read all about it here
            </a>
          </div>
        </label>
      </div>
      <div class="form__submit flex">
        <button
          v-if="Object.keys(currentUser).length"
          :disabled="v.$errors.length"
          class="btn"
          type="submit"
          @click.prevent="submitHandler"
        >
          continue
        </button>
        <button
          v-else
          :disabled="v.$errors.length"
          class="btn"
          type="submit"
          @click.prevent="submitHandler"
        >
          apply
        </button>
        <button
          type="button"
          class="form__cancel"
          @click="$emit('update:showPopup', false)"
        >
          cancel
        </button>
      </div>
      <p
        v-for="(error, index) of v.$errors"
        :key="index"
        class="error-message error"
      >
        {{ error.$message }}
      </p>
    </div>
    <transition name="fade">
      <images-popup
        v-if="showPopup"
        :humanText="texts.humanText"
        :animalsText="texts.animalsText"
        :locationsText="texts.locationsText"
        :modelType="currentModelType"
        v-model:showPopup="showPopup"
      />
    </transition>
    <teleport to="[data-error-popup]">
      <error-popup
        v-model:showPopup="showErrorPopup"
        :errorMessage="errorMessage"
        :white="true"
      />
    </teleport>
    <teleport to="[data-error-popup]">
      <error-popup
        v-if="showLegalPopup"
        v-model:showPopup="showLegalPopup"
        :content="legalText"
        :title="'Legal'"
        :white="true"
      />
    </teleport>
  </form>
</template>

<script>
import { nextTick, reactive, ref, computed, onMounted } from "vue";
import { email, helpers, requiredIf, numeric } from "@vuelidate/validators";
import useVuelidate from "@vuelidate/core";
import ModelForm from "@/components/jobs/ModelForm.vue";
import ImagesPopup from "@/components/jobs/ImagesPopup.vue";
import ErrorPopup from "@/components/ErrorPopup.vue";
import createModelMutation from "@/graphql/createModel.mutation.gql";
import createAnimalMutation from "@/graphql/createAnimal.mutation.gql";
import createLocationMutation from "@/graphql/createLocation.mutation.gql";
import uploadResourceImageMutation from "@/graphql/uploadResourceImage.mutation.gql";
import ApplyExistingResources from "@/graphql/applyExistingResources.mutation.gql";
import { useMutation } from "@vue/apollo-composable";
import { useStore } from "vuex";
export default {
  components: {
    ModelForm,
    ImagesPopup,
    ErrorPopup
  },
  props: {
    modelTypes: {
      type: Array,
      default: () => ["human", "animal", "location"]
    },
    castingID: Number,
    dataReady: Boolean
  },
  emits: [
    "sending",
    "update:showPopup",
    "added-new",
    "update:confirmation",
    "image-added",
    "image-uploaded"
  ],
  setup(props, { emit }) {
    const store = useStore();
    const currentUser = computed(() => store.state.user);
    const texts = computed(() => store.state.pageHome.acf.imagesPopupTexts);
    const legalText = computed(() => store.state.pageHome.acf.legalText);
    const name = ref("");
    const lastName = ref("");
    const emailAddress = ref("");
    const phone = ref("");
    const countryCode = ref("");
    const currentModelType = ref("");
    const politic = ref(false);
    const showPopup = ref(false);
    const submitClick = ref(false);
    const { mutate: createModel } = useMutation(createModelMutation);
    const { mutate: createAnimal } = useMutation(createAnimalMutation);
    const { mutate: createLocation } = useMutation(createLocationMutation);
    const { mutate: uploadImage } = useMutation(uploadResourceImageMutation);
    function imageAdded(value) {
      emit("image-added", value);
    }
    function imageUploaded(value) {
      emit("image-uploaded", value);
    }
    const models = reactive([
      {
        modelType: "human"
      }
    ]);
    const nameErrorMessage = "First name field cannot be empty";
    const lastNameErrorMessage = "Last name field cannot be empty";
    const emailRequiredErrorMessage = "Email field cannot be empty";
    const emailErrorMessage = "Email not valid";
    const phoneRequiredErrorMessage = "This field cannot be empty";
    const numericErrorMessage = "This field should be numeric";
    const codeErrorMessage = "Invalid input format";
    const checkboxRequiredRule = value => {
      if (Object.keys(currentUser.value).length) {
        return true;
      }
      return value === true;
    };
    const countryCodeRule = value => /^\+?[0-9][0-9]$/.test(value);
    const rules = {
      name: {
        required: helpers.withMessage(
          nameErrorMessage,
          requiredIf(() => !Object.keys(currentUser.value).length)
        ),
        $autoDirty: true
      },
      lastName: {
        required: helpers.withMessage(
          lastNameErrorMessage,
          requiredIf(() => !Object.keys(currentUser.value).length)
        ),
        $autoDirty: true
      },
      emailAddress: {
        required: helpers.withMessage(
          emailRequiredErrorMessage,
          requiredIf(() => !Object.keys(currentUser.value).length)
        ),
        email: helpers.withMessage(emailErrorMessage, email),
        $autoDirty: true
      },
      phone: {
        required: helpers.withMessage(phoneRequiredErrorMessage, value =>
          value ? true : false
        ),
        numeric: helpers.withMessage(numericErrorMessage, numeric),
        $$autoDirty: true
      },
      countryCode: {
        required: helpers.withMessage(phoneRequiredErrorMessage, value =>
          value ? true : false
        ),
        numeric: helpers.withMessage(codeErrorMessage, countryCodeRule),
        $$autoDirty: true
      },
      politic: { checkboxRequiredRule, $autoDirty: true }
    };
    const v = useVuelidate(rules, {
      name,
      lastName,
      emailAddress,
      phone,
      countryCode,
      politic
    });
    const dataCounter = ref(0);
    const showErrorPopup = ref(false);
    const errorMessage = ref("");
    const showLegalPopup = ref(false);
    const createdResources = ref([]);
    const { mutate: applyResources } = useMutation(ApplyExistingResources);
    onMounted(() => {
      store.dispatch("onFetchHome");
    });
    return {
      name,
      lastName,
      emailAddress,
      phone,
      countryCode,
      models,
      politic,
      showPopup,
      currentModelType,
      submitClick,
      createModel,
      createAnimal,
      createLocation,
      uploadImage,
      dataCounter,
      showErrorPopup,
      errorMessage,
      showLegalPopup,
      legalText,
      currentUser,
      createdResources,
      applyResources,
      texts,
      imageAdded,
      imageUploaded,
      v
    };
  },
  watch: {
    dataCounter() {
      if (this.dataCounter === this.models.length) {
        if (!this.castingID) {
          this.$router.replace("/confirmation");
          return;
        }
        this.applyResources({
          input: {
            email: this.emailAddress,
            resourceIds: this.createdResources,
            castingId: this.castingID
          }
        })
          .then(({ data }) => {
            if (data.createResourceCasting.errors) {
              this.errorMessage = data.createResourceCasting.errors.message;
              this.showErrorPopup = true;
              return;
            }
            if (Object.keys(this.currentUser).length) {
              this.$emit("added-new", true);
            }
            this.$emit("sending", false);
            this.$emit("update:confirmation", true);
            this.$emit("update:showPopup", false);
          })
          .catch(error => {
            this.errorHandler(error);
          });
      }
    }
  },
  methods: {
    submitHandler() {
      this.submitClick = true;
      this.v.$touch();
      if (this.v.$error) {
        this.submitClick = false;
        return;
      }
      let data = [];
      nextTick(() => {
        this.$emit("sending", true);
        const checkDataReady = () => {
          if (!this.dataReady) {
            setTimeout(checkDataReady, 500);
          } else {
            data = this.convertData();
            data.dataModels.forEach(model => {
              delete model.attributes.images;
              this.createModel({ input: model })
                .then((response) => {
                  const id = response?.data?.createModel?.resource?.id;
                  if (id) {
                    this.createdResources = [ ...this.createdResources, id ];
                  }
                  this.dataCounter++;
                })
                .catch(e => {
                  this.errorHandler(e);
                });
            });
            data.dataAnimals.forEach(model => {
              delete model.attributes.images;
              this.createAnimal({ input: model })
                .then((response) => {
                  const id = response?.data?.createAnimal?.resource?.id;
                  if (id) {
                    this.createdResources = [ ...this.createdResources, id ];
                  }
                  this.dataCounter++;
                })
                .catch(e => {
                  this.errorHandler(e);
                });
            });
            data.dataLocations.forEach(model => {
              delete model.attributes.images;
              this.createLocation({ input: model })
                .then((response) => {
                  const id = response?.data?.createLocation?.resource?.id;
                  if (id) {
                    this.createdResources = [ ...this.createdResources, id ];
                  }
                  this.dataCounter++;
                })
                .catch(e => {
                  this.errorHandler(e);
                });
            });
          }
        };
        checkDataReady();
      });
    },
    errorHandler(error) {
      this.$emit("sending", false);
      this.submitClick = false;
      this.showErrorPopup = true;
      this.errorMessage = `${error.message}`;
    },
    addAnotherHandler() {
      if (this.models.length < 8) {
        this.models.push({
          modelType: "human",
          modelName: "",
          modelLastName: "",
          modelBirth: "",
          modelGender: "",
          modelHeight: "",
          images: []
        });
      }
    },
    showPopupHandler(modelType) {
      if (modelType) {
        this.currentModelType = modelType;
      }
      this.showPopup = true;
      document.body.classList.add("overflow");
    },
    deleteItem(index) {
      this.models.splice(index, 1);
    },
    convertData() {
      let data = {};
      let dataModels = [];
      let dataAnimals = [];
      let dataLocations = [];
      let phoneNumber = "";
      if (this.phone[0] === "0") {
        phoneNumber = this.phone.slice(1);
      } else {
        phoneNumber = this.phone;
      }
      let countryCode = "";
      if (this.countryCode[0] === "+") {
        countryCode = this.countryCode;
      } else {
        countryCode = "+" + this.countryCode;
      }
      this.models.forEach(item => {
        if (item.modelType === "human" || item.modelType === "model") {
          dataModels.push({
            attributes: {
              contactFirstName: this.name,
              contactLastName: this.lastName,
              email: this.emailAddress,
              phone: countryCode + phoneNumber,
              locationName: item.modelAddress,
              instagram: item.modelInstagram,
              resourceImageIds: item.resourceImageIds,
              resourceModelAttributes: {
                firstName: item.modelName,
                lastName: item.modelLastName,
                dob: item.modelBirth,
                gender: item.modelGender,
                height: +item.modelHeight
              }
            }
          });
        } else if (item.modelType === "location") {
          dataLocations.push({
            attributes: {
              contactFirstName: this.name,
              contactLastName: this.lastName,
              email: this.emailAddress,
              phone: countryCode + phoneNumber,
              locationName: item.locationAddress,
              instagram: item.locationInstagram,
              description: item.locationDescription,
              otherInformation: item.locationCharacteristics,
              resourceImageIds: item.resourceImageIds,
              resourceLocationAttributes: {
                address: "",
                size: +item.locationSize,
                buildYear: +item.locationBuilt,
                residenceTypeId: item.locationType,
                residenceTypeSpecify: item.locationTypeSpecify
              }
            }
          });
        } else if (item.modelType === "animal") {
          dataAnimals.push({
            attributes: {
              contactFirstName: this.name,
              contactLastName: this.lastName,
              email: this.emailAddress,
              locationName: item.animalAddress,
              instagram: item.animalInstagram,
              phone: countryCode + phoneNumber,
              resourceImageIds: item.resourceImageIds,
              resourceAnimalAttributes: {
                name: item.animalName,
                birthYear: item.animalBirth,
                gender: item.animalGender.toLowerCase(),
                height: +item.animalHeight,
                animalGroupTitle: item.animalType,
                animalBreedTitle: item.animalBreed
              }
            }
          });
        }
      });
      data = {
        dataModels: dataModels,
        dataAnimals: dataAnimals,
        dataLocations: dataLocations
      };
      return data;
    },
    addAttachment(array) {
      return new Promise((resolve, reject) => {
        let results = [];
        array.forEach(element => {
          this.uploadImage({ file: element })
            .then(response => {
              results.push(response.data.uploadResourceImage.resourceImage.id);
              if (results.length === array.length) {
                resolve(results);
              }
            })
            .catch(error => {
              reject(error);
            });
        });
      });
    }
  }
};
</script>

<style lang="scss">
@import "~@/assets/style/views/home/form.scss";
.form {
  padding-top: 50px;
  @media screen and (max-width: 767px) {
    padding-top: 32px;
  }
}
</style>
