<template>
  <div class="jobs-page">
    <transition name="camillaLoader">
      <div v-if="!imagesReady || firstLoading" class="loader"></div>
    </transition>
    <transition name="fadetext" @before-leave="beforeLeave">
      <div v-if="!imagesReady || firstLoading" class="loader-text">
        Loading <span>{{ Math.round(progress) }}%</span>
      </div>
    </transition>
    <Header />
    <div class="container">
      <div class="row">
        <div class="jobs__filters col-3 col-sm-6" />
        <div class="col-6 col-sm-12">
          <h2 class="container__title">{{ jobsTitle }}</h2>
          <div v-if="jobs.length" class="container__description">
            <p>
              Below you find a list of current jobs and castings. If there's no suitable job available, you can still
              <router-link to="/apply">apply here</router-link>
            </p>
          </div>
        </div>
        <div class="jobs__filters col-3 col-sm-6" />
      </div>
      <div class="jobs row">
        <div class="jobs__filters col-3 col-sm-6">
          <!-- <div class="jobs__title">Filter</div>
          <button
            class="filter-item"
            :class="[selectedFilters.length ? '' : 'active']"
            @click="resetFilters"
          >
            All
          </button>
          <button
            v-for="(filter, i) in filters"
            :key="i"
            :class="[selectedFilters.includes(filter) ? 'active' : '']"
            class="filter-item"
            @click="updateFilters(filter)"
          >
            {{ filter }}s
          </button> -->
        </div>
        <transition name="fade">
          <div
            v-if="!isSorting && !loading"
            class="jobs__content  col-6 col-sm-12"
          >
            <div v-if="!loading && jobs.length">
              <router-link
                v-for="job in jobs"
                :key="job.node.id"
                :to="`/jobs/${job.node.id}`"
                class="jobs__item jobs-item"
              >
                <h5 class="jobs-item__title">
                  {{ job.node.name.replace(/\//g, "&#47;\u200B") }}
                </h5>
                <div class="jobs-item__info">
                  <span style="text-transform: capitalize;">
                    <span
                      v-for="(type, index) in job.node.resourceTypes"
                      :key="index"
                    >
                      {{ type }}s<span
                        v-if="index === job.node.resourceTypes.length - 2"
                      > & </span>
                      <span
                        v-else-if="index < job.node.resourceTypes.length - 1"
                      >, </span>
                    </span>.
                  </span>
                  <span v-if="job.node.until">
                    Apply by
                    {{ new Date(job.node.until).toISOString().slice(0, 10) }}
                  </span>
                </div>
              </router-link>
            </div>
            <div v-else class="jobs__empty container__description">
              <span v-if="!selectedFilters.length">
                Right now all productions are stacked with humans, animals and
                locations. Please check-in again soon to find new castings.
                Also, you can still apply <router-link to="/apply">here</router-link>.
              </span>
              <span
                v-if="selectedFilters.length && selectedFilters[0] === 'animal'"
              >
                No work for animals right now. Pop in again soon for new
                opportu-nities!
              </span>
              <span
                v-if="selectedFilters.length && selectedFilters[0] === 'model'"
              >
                Sorry, no castings at the moment. Please check-in again soon and
                we’ll hopefully have something for you.
              </span>
              <span
                v-if="
                  selectedFilters.length && selectedFilters[0] === 'location'
                "
              >
                Seems there’s no demand for locations today. But that’ll surely
                change, welcome back in a while!
              </span>
            </div>
          </div>
        </transition>
        <div class="jobs__order col-3 col-sm-6">
         <!-- <div class="jobs__order-wrap">
            <div class="jobs__title">Order</div>
            <button
              v-for="(order, i) in orders"
              :key="i"
              :class="[order.name === selectedOrder ? 'active' : '']"
              class="filter-item"
              @click="changeOrder(order.name)"
            >
              {{ order.title }}
            </button>
          </div> -->
        </div>
      </div>
    </div>
    <!-- <work-with-us
      class="jobs-work"
      v-if="isPageReady"
      :work="homePage.acf.work"
    /> -->
    <Footer />
  </div>
</template>

<script>
import { ref, computed, onMounted, reactive } from "vue";
import { useStore } from "vuex";
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
// import FlexTitle from "@/components/castings/FlexTitle.vue";
// import WorkWithUs from "@/components/home/Work.vue";

import jobsQuery from "@/graphql/jobs.query.gql";
import { useQuery, useResult } from "@vue/apollo-composable";
export default {
  components: {
    Header,
    Footer,
    // FlexTitle,
    // WorkWithUs
  },
  setup() {
    const store = useStore();
    const isPageReady = computed(() => (homePage.value.acf ? true : false));
    const homePage = computed(() => store.state.pageHome);
    const jobsTitle = ref("Current Jobs");
    const selectedFilters = ref([]);
    const filters = ["model", "location", "animal"];
    const orders = [
      {
        title: "Newest",
        name: "latest"
      },
      {
        title: "Oldest",
        name: "oldest"
      }
    ];
    const selectedOrder = ref("latest");
    const variables = reactive({
      resourceType: "all",
      order: "latest"
    });
    const { result, loading } = useQuery(jobsQuery, variables, {
      fetchPolicy: "cache-and-network"
    });
    const jobs = useResult(result, [], data => data.castings.edges);
    const resetFilters = () => {
      isSorting.value = true;
      selectedFilters.value = [];
      variables.resourceType = "all";
      setTimeout(() => {
        isSorting.value = false;
      }, 200);
    };
    const updateFilters = filter => {
      isSorting.value = true;
      if (
        !selectedFilters.value.length &&
        !selectedFilters.value.includes(filter)
      ) {
        selectedFilters.value.push(filter);
        variables.resourceType = filter;
      } else if (selectedFilters.value.includes(filter)) {
        const index = selectedFilters.value.indexOf(filter);
        selectedFilters.value.splice(index, 1);
        filter = "all";
        variables.resourceType = filter;
      } else {
        selectedFilters.value = [];
        selectedFilters.value.push(filter);
        variables.resourceType = filter;
      }
      setTimeout(() => {
        isSorting.value = false;
      }, 200);
    };
    const isSorting = ref(false);
    const changeOrder = order => {
      if (selectedOrder.value !== order) {
        isSorting.value = true;
        selectedOrder.value = order;
        variables.order = order;
        setTimeout(() => {
          isSorting.value = false;
        }, 200);
      }
    };
    const beforeLeave = () => {
      progress.value = 100;
    };
    onMounted(() => {
      store.dispatch("onFetchHome");
      setTimeout(() => {
        imagesReady.value = true;
      }, 200);
    });
    const progress = ref(0);
    const imagesReady = ref(false);
    const firstLoading = ref(true);
    return {
      jobsTitle,
      isPageReady,
      homePage,
      selectedFilters,
      resetFilters,
      isSorting,
      updateFilters,
      filters,
      orders,
      selectedOrder,
      changeOrder,
      jobs,
      loading,
      imagesReady,
      progress,
      beforeLeave,
      firstLoading
    };
  },
  watch: {
    loading() {
      if (this.loading === false) {
        this.firstLoading = false;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import "~@/assets/style/views/jobs/jobs.scss";
</style>
