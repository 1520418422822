<template>
  <div class="confirmation-page">
    <Header />
    <div class="container">
      <div class="confirmation">
        <h1 v-if="confirmationPage.title" class="confirmation__title">
          {{ confirmationPage.title.rendered }}
        </h1>
        <div
          v-if="confirmationPage.acf"
          class="confirmation__content"
          v-html="confirmationPage.acf.text"
        ></div>
        <div class="confirmation__close">
          <button class="btn" type="button" @click="closeClick">OK</button>
        </div>
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
import { computed, onMounted } from "vue";
import { useStore } from "vuex";
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
export default {
  components: {
    Header,
    Footer
  },
  setup() {
    const store = useStore();
    const confirmationPage = computed(() => store.state.pageConfirmation);
    onMounted(() => {
      store.dispatch("onFetchConfirmation");
    });
    return {
      confirmationPage
    };
  },
  methods: {
    closeClick() {
      this.$router.replace("/");
    }
  }
};
</script>

<style lang="scss">
@import "~@/assets/style/views/confirmation/confirmation.scss";
</style>
