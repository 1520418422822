<template>
  <div class="container portfolio-content">
    <div class="row">
      <div class="col-3 col-sm-12"></div>
      <div class="col-6 col-sm-12">
        <div class="portfolio-content__title content-title">
          {{ portfolio.title.rendered }}
        </div>
        <div
          v-if="portfolio.acf.description"
          class="portfolio-content__description"
          v-html="portfolio.acf.description"
        ></div>
        <div v-if="portfolio.acf.images" class="portfolio-content__images">
          <div
            v-for="(image, index) in portfolio.acf.images"
            :key="index"
            class="portfolio-content__img"
          >
            <img
              :srcset="image.image.srcset"
              :src="image.image.sizes.large"
              alt=""
              @load="imgLoaded"
            />
          </div>
        </div>
      </div>
      <div class="col-3 col-sm-12">
        <button class="portfolio-content__back" @click="goBack">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="15.556"
            height="15.556"
            viewBox="0 0 15.556 15.556"
          >
            <rect
              width="3"
              height="19"
              transform="translate(0 2.121) rotate(-45)"
            />
            <rect
              width="3"
              height="19"
              transform="translate(2.121 15.556) rotate(-135)"
            />
          </svg>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
import { useRoute } from "vue-router";
export default {
  async setup() {
    const route = useRoute();
    const portfolio = await fetch(
      `https://cms.lomocast.com/wp-json/wp/v2/posts?slug=${route.params.slug}`
    )
      .then(res => res.json())
      .then(res => res[0]);
    const loadedImages = ref(0);
    const progress = ref(0);
    const imagesReady = ref(false);
    return {
      portfolio,
      loadedImages,
      progress,
      imagesReady
    };
  },
  mounted() {
    if (!this.portfolio.acf.images) {
      this.progress = 100;
      this.$emit("update:progress", this.progress);
      this.imagesReady = true;
      this.$emit("update:imagesReady", this.imagesReady);
    }
  },
  methods: {
    imgLoaded() {
      this.loadedImages++;
      this.progress =
        (this.loadedImages / this.portfolio.acf.images.length) * 100;
      this.$emit("update:progress", this.progress);
      this.$nextTick(() => {
        if (this.loadedImages >= this.portfolio.acf.images.length) {
          this.imagesReady = true;
          this.$emit("update:imagesReady", this.imagesReady);
        }
      });
    },
    goBack(e) {
      e.preventDefault();
      if (!window.history.state.back) {
        this.$router.push(`/${this.portfolio.acf.category}s`);
      } else {
        this.$router.go(-1);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import "~@/assets/style/views/portfolio/portfolioContent.scss";
</style>
