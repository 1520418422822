<template>
  <div v-if="content" class="how-works">
    <div class="container how-works__container">
      <h3 class="how-works__title content-title">{{ content.title }}</h3>
      <div class="how-works__content row">
        <div class="col-3 col-md-2 col-sm-12"></div>
        <div class="col-6 col-md-8 col-sm-12">
          <div
            class="how-works__text"
            v-html="content.text"
            @click="clickHandler"
          ></div>
        </div>
        <div class="col-3 col-md-2 col-sm-12"></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    content: Object
  },
  setup() {
    return {};
  },
  methods: {
    clickHandler(e) {
      e.preventDefault();
      const href = e.target.getAttribute("href");
      if (href) {
        this.$router.push(href);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import "~@/assets/style/views/home/howWorks.scss";
</style>
