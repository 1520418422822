<template>
  <div class="autocomplete custom-select" :tabindex="tabindex">
    <div
      class="custom-select__selected"
      :class="{
        open: filteredSuggestions.length,
        error: error
      }"
    >
      <input
        v-model="selected"
        :placeholder="placeholder"
        type="text"
        @input="inputHandler"
      />
    </div>
    <div v-if="filteredSuggestions.length" class="custom-select__list">
      <div
        v-for="(option, index) in filteredSuggestions"
        :key="index"
        class="custom-select__item"
        @click="selectHandle(option)"
      >
        {{ option }}
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
export default {
  props: {
    options: Array,
    tabindex: Number,
    error: Boolean,
    placeholder: String
  },
  emits: ["selected"],
  setup() {
    const selected = ref("");
    const filteredSuggestions = ref([]);
    return {
      selected,
      filteredSuggestions
    };
  },
  methods: {
    selectHandle(option) {
      this.selected = option;
      this.filteredSuggestions = [];
      this.$emit("selected", option);
    },
    inputHandler(event) {
      this.selected = event.target.value;
      this.$emit("selected", this.selected);
      if (this.selected.length) {
        this.filteredSuggestions = this.options.filter(
          suggestion =>
            suggestion.toLowerCase().indexOf(this.selected.toLowerCase()) > -1
        );
      } else {
        this.filteredSuggestions = [];
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import "~@/assets/style/views/jobs/custom-select.scss";
</style>
