import { createStore } from "vuex";

export default createStore({
  state: {
    pageHome: {},
    pageAbout: {},
    pageContact: {},
    pageLegal: {},
    pageConfirmation: {},
    mainMenu: {},
    portfolios: [],
    user: {}
  },
  mutations: {
    fetchPage: (state, page) => (state["page"] = page),
    fetchHome: (state, page) => (state.pageHome = page),
    fetchAbout: (state, page) => (state.pageAbout = page),
    fetchContact: (state, page) => (state.pageContact = page),
    fetchLegal: (state, page) => (state.pageLegal = page),
    fetchConfirmation: (state, page) => (state.pageConfirmation = page),
    fetchMenu: (state, menu) => (state.mainMenu = menu),
    fetchPortfolios: (state, portfolios) => (state.portfolios = portfolios),
    fetchUser: (state, user) => (state.user = user)
  },
  actions: {
    onFetchHome: async ({ commit }) => {
      const response = await fetch(
        "https://cms.lomocast.com/wp-json/wp/v2/pages?slug=home"
      ).then(res => res.json());
      commit("fetchHome", response[0]);
    },
    onFetchPage: async ({ commit }, slug) => {
      const response = await fetch(
        `https://cms.lomocast.com/wp-json/wp/v2/pages?slug=${slug}`
      ).then(res => res.json());
      commit("fetchPage", response[0]);
    },
    onFetchAbout: async ({ commit }) => {
      const response = await fetch(
        "https://cms.lomocast.com/wp-json/wp/v2/pages?slug=about"
      ).then(res => res.json());
      commit("fetchAbout", response[0]);
    },
    onFetchContact: async ({ commit }) => {
      const response = await fetch(
        "https://cms.lomocast.com/wp-json/wp/v2/pages?slug=contact"
      ).then(res => res.json());
      commit("fetchContact", response[0]);
    },
    onFetchLegal: async ({ commit }) => {
      const response = await fetch(
        "https://cms.lomocast.com/wp-json/wp/v2/pages?slug=legal"
      ).then(res => res.json());
      commit("fetchLegal", response[0]);
    },
    onFetchConfirmation: async ({ commit }) => {
      const response = await fetch(
        "https://cms.lomocast.com/wp-json/wp/v2/pages?slug=confirmation"
      ).then(res => res.json());
      commit("fetchConfirmation", response[0]);
    },
    onFetchMenu: async ({ commit }) => {
      const response = await fetch(
        "https://cms.lomocast.com/wp-json/wp/v2/menu"
      ).then(res => res.json());
      commit("fetchMenu", response);
    },
    onFetchPortfolios: async ({ commit }, { category }) => {
      try {
        let response = await fetch(
          `https://cms.lomocast.com/wp-json/wp/v2/posts?categories=${category}`
        ).then(res => res.json());

        response = response.filter(el => el.status === "publish");
        commit("fetchPortfolios", response);
      } catch (error) {
        commit("fetchPortfolios", []);
        console.log(error);
      }
    },
    onFetchUser: ({ commit }, user) => {
      commit("fetchUser", user);
    },
    checkAuth: ({ commit }) => {
      const user = window.localStorage.getItem("user");
      if (user) {
        commit("fetchUser", JSON.parse(user));
      }
    }
  },
  modules: {}
});
