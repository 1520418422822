<template>
  <div class="form-group" :class="{ error: v.modelName.$errors.length }">
    <label class="form-label" for="modelName">First name</label>
    <input
      v-model="modelHuman.modelName"
      class="form-input"
      type="text"
      placeholder="Enter first name of model"
    />
    <p
      v-for="(error, index) of v.modelName.$errors"
      :key="index"
      class="error-message"
    >
      {{ error.$message }}
    </p>
  </div>
  <div class="form-group" :class="{ error: v.modelLastName.$errors.length }">
    <label class="form-label" for="modelLastName">Last name</label>
    <input
      v-model="modelHuman.modelLastName"
      class="form-input"
      type="text"
      placeholder="Enter last name of model"
    />
    <p
      v-for="(error, index) of v.modelLastName.$errors"
      :key="index"
      class="error-message"
    >
      {{ error.$message }}
    </p>
  </div>
  <div
    class="form-group"
    :class="{
      error:
        v.month.$errors.length || v.year.$errors.length || v.day.$errors.length
    }"
  >
    <label class="form-label" for="modelBirth">Date of birth</label>
    <div class="row">
      <div class="col-4 col-sm-12 form-col">
        <autocomplete
          :options="yearList"
          :placeholder="'Year'"
          :error="v.year.$errors.length"
          @selected="
            event => {
              this.year = event;
              this.modelHuman.modelBirth = this.birthDate;
            }
          "
        />
        <p
          v-for="(error, index) of v.year.$errors"
          :key="index"
          class="error-message"
        >
          {{ error.$message }}
        </p>
      </div>
      <div class="col-4 col-sm-12 form-col">
        <custom-select
          :options="monthList"
          :tabindex="1"
          :defaultValue="'Month'"
          :error="v.month.$errors.length"
          @input="
            event => {
              this.month = this.monthList.indexOf(event) + 1;
              this.modelHuman.modelBirth = this.birthDate;
            }
          "
        />
        <p
          v-for="(error, index) of v.month.$errors"
          :key="index"
          class="error-message"
        >
          {{ error.$message }}
        </p>
      </div>
      <div class="col-4 col-sm-12 form-col">
        <custom-select
          :options="dayList"
          :tabindex="1"
          :defaultValue="'Day'"
          :error="v.day.$errors.length"
          @input="
            event => {
              this.day = event;
              this.modelHuman.modelBirth = this.birthDate;
            }
          "
        />
        <p
          v-for="(error, index) of v.day.$errors"
          :key="index"
          class="error-message"
        >
          {{ error.$message }}
        </p>
      </div>
    </div>
  </div>
  <div class="form-group" :class="{ error: v.modelGender.$errors.length }">
    <label class="form-label" for="modelGender">Gender</label>
    <custom-select
      :options="genderList.map(e => (e.title ? e.title : null))"
      :tabindex="1"
      :defaultValue="'Model gender'"
      :error="v.modelGender.$errors.length"
      @input="
        event => {
          this.modelHuman.modelGender = genderList.filter(
            e => e.title === event
          )[0].slug;
        }
      "
    />
    <p
      v-for="(error, index) of v.modelGender.$errors"
      :key="index"
      class="error-message"
    >
      {{ error.$message }}
    </p>
  </div>
  <div class="form-group" :class="{ error: v.modelHeight.$errors.length }">
    <label class="form-label" for="modelHeight">Height</label>
    <input
      v-model="modelHuman.modelHeight"
      class="form-input"
      type="text"
      placeholder="Enter the height of the model in centimeters"
    />
    <p
      v-for="(error, index) of v.modelHeight.$errors"
      :key="index"
      class="error-message"
    >
      {{ error.$message }}
    </p>
  </div>
  <div class="form-group" :class="{ error: v.modelAddress.$errors.length }">
    <label class="form-label">Address</label>
    <address-autocomplete
      v-model:modelData="modelHuman.modelAddress"
      :placeholder="'Enter the primary location of the model'"
    />
    <p
      v-for="(error, index) of v.modelAddress.$errors"
      :key="index"
      class="error-message"
    >
      {{ error.$message }}
    </p>
  </div>
  <div class="form-group" :class="{ error: v.modelInstagram.$errors.length }">
    <label class="form-label">Instagram (optional)</label>
    <input
      v-model="modelHuman.modelInstagram"
      class="form-input"
      type="text"
      placeholder="Enter Instagram username or URL of the model"
    />
    <p
      v-for="(error, index) of v.modelInstagram.$errors"
      :key="index"
      class="error-message"
    >
      {{ error.$message }}
    </p>
  </div>
  <div class="form-group" :class="{ error: v.resourceImageIds.$errors.length }">
    <div class="form-label">Images</div>
    <DragAndDrop
      v-model:images="modelHuman.images"
      v-model:resourceImageIds="modelHuman.resourceImageIds"
      resourceType="model"
      @image-added="imageAdded"
      @image-uploaded="imageUploaded"
    />
    <p
      v-for="(error, index) of v.resourceImageIds.$errors"
      :key="index"
      class="error-message"
    >
      {{ error.$message }}
    </p>
  </div>
</template>

<script>
import { reactive, ref, computed, toRef, watch } from "vue";
import { helpers, numeric, required, integer } from "@vuelidate/validators";
import DragAndDrop from "@/components/jobs/DragAndDrop";
import addressAutocomplete from "@/components/jobs/addressAutocomplete.vue";
import Autocomplete from "@/components/jobs/Autocomplete.vue";
import CustomSelect from "@/components/jobs/CustomSelect";
import useVuelidate from "@vuelidate/core";
export default {
  components: {
    DragAndDrop,
    CustomSelect,
    addressAutocomplete,
    Autocomplete
  },
  props: {
    submitClick: Boolean,
    model: Object
  },
  emits: ["update:model", "modelUpdate", "image-added", "image-uploaded"],
  setup(props, { emit }) {
    const modelHuman = reactive({
      modelType: "human",
      modelName: "",
      modelLastName: "",
      modelBirth: "",
      modelGender: "",
      modelHeight: "",
      modelInstagram: "",
      modelAddress: "",
      images: [],
      resourceImageIds: []
    });
    const genderList = [
      {
        title: "Female",
        slug: "female"
      },
      {
        title: "Male",
        slug: "male"
      },
      {
        title: "Non-binary",
        slug: "non_binary"
      },
      {
        title: "Don’t want to respond",
        slug: "undisclosed"
      }
    ];
    const year = ref("");
    const month = ref("");
    const day = ref("");
    const yearList = [];
    const monthList = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December"
    ];
    const daysNumber = computed(() =>
      month.value && year.value
        ? new Date(year.value, month.value, 0).getDate()
        : 31
    );
    const dayList = computed(() => {
      let list = [];
      for (let i = 1; i <= daysNumber.value; i++) {
        list.push(i);
      }
      return list;
    });
    const birthDate = computed(
      () =>
        `${year.value}-${month.value < 10 ? "0" + month.value : month.value}-${
          day.value < 10 ? "0" + day.value : day.value
        }`
    );
    const currentYear = new Date().getFullYear();
    for (let i = 1900; i <= currentYear; i++) {
      yearList.push(`${i}`);
    }
    watch(
      () => props.submitClick,
      newValue => {
        if (newValue) {
          emit("update:model", modelHuman);
          emit("model-update");
        }
      }
    );
    const numericErrorMessage = "This field should be numeric";
    const minHeightErrorMessage = "Height must be greater than or equal to 1";
    const maxHeightErrorMessage = "Height must be less than or equal to 250";
    const integerHeightErrorMessage = "Height must be integer";
    const yearErrorMessage = "Year not valid";
    const selectRequiredRule = value => (value ? true : false);
    const yearRule = value => yearList.indexOf(value) >= 0;
    const rules = {
      modelName: {
        required: helpers.withMessage(
          "First name field cannot be empty",
          required
        ),
        $autoDirty: true
      },
      modelLastName: {
        required: helpers.withMessage(
          "Last name field cannot be empty",
          value => (value ? true : false)
        ),
        $autoDirty: true
      },
      year: {
        required: helpers.withMessage(yearErrorMessage, yearRule),
        $autoDirty: true
      },
      month: {
        required: helpers.withMessage(
          "Month field cannot be empty",
          selectRequiredRule
        ),
        $autoDirty: true
      },
      day: {
        required: helpers.withMessage(
          "Day field cannot be empty",
          selectRequiredRule
        ),
        $autoDirty: true
      },
      modelGender: {
        required: helpers.withMessage("Gender field cannot be empty", value =>
          value ? true : false
        ),
        $autoDirty: true
      },
      modelHeight: {
        required: helpers.withMessage("Height field cannot be empty", value =>
          value ? true : false
        ),
        numeric: helpers.withMessage(numericErrorMessage, numeric),
        minValue: helpers.withMessage(
          minHeightErrorMessage,
          value => value >= 1
        ),
        maxValue: helpers.withMessage(
          maxHeightErrorMessage,
          value => value <= 250
        ),
        integer: helpers.withMessage(integerHeightErrorMessage, integer),
        $autoDirty: true
      },
      modelAddress: {
        required: helpers.withMessage("Address field cannot be empty", value =>
          value ? true : false
        ),
        $autoDirty: true
      },
      modelInstagram: {},
      resourceImageIds: {
        maxValue: helpers.withMessage(
          "At least one image required",
          value => value && value.length >= 1
        ),
        $autoDirty: true
      }
    };
    const v = useVuelidate(rules, {
      modelName: toRef(modelHuman, "modelName"),
      modelLastName: toRef(modelHuman, "modelLastName"),
      year: toRef(year, "value"),
      month: toRef(month, "value"),
      day: toRef(day, "value"),
      modelGender: toRef(modelHuman, "modelGender"),
      modelHeight: toRef(modelHuman, "modelHeight"),
      modelAddress: toRef(modelHuman, "modelAddress"),
      modelInstagram: toRef(modelHuman, "modelInstagram"),
      resourceImageIds: toRef(modelHuman, "resourceImageIds")
    });
    function imageAdded(value) {
      emit("image-added", value);
    }
    function imageUploaded(value) {
      emit("image-uploaded", value);
    }
    return {
      modelHuman,
      year,
      day,
      month,
      currentYear,
      yearList,
      monthList,
      birthDate,
      daysNumber,
      dayList,
      genderList,
      imageAdded,
      imageUploaded,
      v
    };
  }
};
</script>
