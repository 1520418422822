<template>
  <div class="add-model-popup">
    <div class="container">
      <div class="add-model-popup__title">Choose resource to apply with</div>
      <div class="add-model-popup__description">
        Please choose the resource to apply with by clicking the portraits
        below.
      </div>
      <div class="row add-model-popup__models">
        <div
          v-for="(resource, i) in resources"
          :key="i"
          class="col-4 col-sm-12 add-model-popup__col"
        >
          <div
            class="add-model"
            :class="{ active: this.addedModels.includes(resource.id) }"
            @click="addModel(resource.id)"
          >
            <div class="add-model__img">
              <img
                v-if="resource.profileImage"
                :src="`${imageUrl}${resource.profileImage.file.thumb.url}`"
                alt=""
              />
            </div>
            <div
              v-if="resource.resourceType === 'model'"
              class="add-model__info"
            >
              <div class="add-model__name">
                {{ resource.resourceModel.firstName }}
                {{ resource.resourceModel.ageInYears }}
              </div>
              <div class="add-model__type">
                <span>
                  {{ resource.resourceType }}
                </span>
                <a
                  :href="
                    `${userFrontendBaseUrl}/account/resources/${resource.id}/`
                  "
                  class="add-model__link"
                >
                  View
                </a>
              </div>
            </div>
            <div
              v-if="resource.resourceType === 'animal'"
              class="add-model__info"
            >
              <div class="add-model__name">
                {{ resource.resourceAnimal.name }}
                {{ resource.resourceAnimal.ageInYears }}
              </div>
              <div class="add-model__type">
                <span>
                  {{ resource.resourceType }}
                </span>
                <a
                  :href="
                    `${userFrontendBaseUrl}/account/resources/${resource.id}/`
                  "
                  class="add-model__link"
                >
                  View
                </a>
              </div>
            </div>
            <div
              v-if="resource.resourceType === 'location'"
              class="add-model__info"
            >
              <div class="add-model__name">
                {{ resource.resourceLocation.name }}
                {{ resource.resourceLocation.buildYear }}
              </div>
              <div class="add-model__type">
                <span>
                  {{ resource.resourceType }}
                </span>
                <a
                  :href="
                    `${userFrontendBaseUrl}/account/resources/${resource.id}/`
                  "
                  class="add-model__link"
                >
                  View
                </a>
              </div>
            </div>
          </div>
        </div>
        <div class="col-4 col-sm-12 add-model-popup__col">
          <div class="add-model add-new" @click="addNew">
            + Add new
          </div>
        </div>
      </div>
      <div class="add-model-popup__submit">
        <button
          type="button"
          class="add-model-popup__cancel"
          @click="$emit('update:addModels', false)"
        >
          cancel
        </button>

        <button
          :disabled="!addedModels.length"
          class="btn"
          type="submit"
          @click.prevent="submitHandler"
        >
          continue
        </button>
      </div>
    </div>
    <teleport to="[data-error-popup]">
      <error-popup
        v-model:showPopup="showErrorPopup"
        :errorMessage="errorMessage"
        :white="true"
      />
    </teleport>
  </div>
</template>

<script>
import ExistingResources from "@/graphql/existingResources.query.gql";
import ApplyExistingResources from "@/graphql/applyExistingResources.mutation.gql";
import ErrorPopup from "@/components/ErrorPopup.vue";
import { useMutation, useQuery, useResult } from "@vue/apollo-composable";
import { ref } from "vue";
export default {
  props: {
    modelTypes: Array,
    addModels: Boolean,
    castingID: Number
  },
  emits: ["update:addModels", "resources-added", "add-new"],
  components: {
    ErrorPopup
  },
  setup(props) {
    const { result, loading } = useQuery(ExistingResources, {
      resourceTypes: props.modelTypes
    });
    const addedModels = ref([]);
    const resources = useResult(
      result,
      [],
      data => data.user.userModel.resources
    );
    const imageUrl = process.env.VUE_APP_API_URL;
    const { mutate: applyResources } = useMutation(ApplyExistingResources);
    const errorMessage = ref("");
    const showErrorPopup = ref(false);
    const userFrontendBaseUrl = process.env.VUE_APP_USER_FRONTEND_BASE_URL;

    return {
      loading,
      resources,
      imageUrl,
      addedModels,
      applyResources,
      errorMessage,
      showErrorPopup,
      userFrontendBaseUrl
    };
  },
  methods: {
    addModel(id) {
      const index = this.addedModels.indexOf(id);
      if (index > -1) {
        this.addedModels.splice(index, 1);
      } else {
        this.addedModels.push(id);
      }
    },
    submitHandler() {
      this.applyResources({
        input: {
          resourceIds: this.addedModels,
          castingId: this.castingID
        }
      }).then(({ data }) => {
        if (data.createResourceCasting.errors) {
          this.errorMessage = data.createResourceCasting.errors.message;
          this.showErrorPopup = true;
          return;
        }
        this.$emit("resources-added", true);
        this.$emit("update:addModels", false);
      });
    },
    addNew() {
      this.$emit("add-new", true);
      this.$emit("update:addModels", false);
    }
  }
};
</script>

<style lang="scss" scoped>
@import "~@/assets/style/views/jobs/add-model.scss";
</style>
