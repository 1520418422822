<template>
  <router-view :style="cssVars" v-slot="{ Component }">
    <transition name="lomocast" mode="out-in">
      <component :is="Component"></component>
    </transition>
  </router-view>
</template>

<script>
import { computed, onMounted, ref } from "vue";
import { useStore } from "vuex";
export default {
  setup() {
    const scrollHeight = ref(0);
    const store = useStore();
    const cssVars = computed(() => {
      return { "--scrollHeight": scrollHeight.value + "px" };
    });
    const debounce = (func, wait, immediate) => {
      let timeout;
      return function() {
        const context = this;
        const args = arguments;
        clearTimeout(timeout);
        timeout = setTimeout(function() {
          timeout = null;
          if (!immediate) func.apply(context, args);
        }, wait);
        if (immediate && !timeout) func.apply(context, args);
      };
    };
    onMounted(() => {
      window.addEventListener(
        "scroll",
        debounce(() => {
          scrollHeight.value = window.scrollY;
        }),
        100
      );
      store.dispatch("checkAuth");
    });
    return {
      cssVars
    };
  }
};
</script>
<style lang="scss">
@import "~@/assets/style/fonts.scss";
@import "~@/assets/style/main.scss";
</style>
