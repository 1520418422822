import { createRouter, createWebHistory } from "vue-router";
import Home from "../views/Home.vue";
import Models from "../views/Models.vue";
import Animals from "../views/Animals.vue";
import Locations from "../views/Locations.vue";
import Login from "@/views/Login.vue";
import Jobs from "@/views/Jobs.vue";
import Portfolio from "@/views/Portfolio.vue";
import Job from "@/views/Job.vue";
import About from "@/views/About.vue";
import Contact from "@/views/Contact.vue";
import Legal from "@/views/Legal.vue";
import Apply from "@/views/Apply.vue";
import Confirmation from "@/views/Confirmation.vue";
import ClientRegistration from "@/views/ClientRegistration.vue";
import ForgotPassword from "@/views/ForgotPassword.vue";
import ResetPassword from "@/views/ResetPassword.vue";
import SetPassword from "@/views/SetPassword.vue";
import Simple from "../views/SimplePage.vue";

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home
  },
  {
    path: "/models",
    name: "Models",
    component: Models
  },
  {
    path: "/animals",
    name: "Animals",
    component: Animals
  },
  {
    path: "/locations",
    name: "Locations",
    component: Locations
  },
  {
    path: "/login",
    name: "Login",
    component: Login
  },
  {
    path: "/portfolio/:slug",
    name: "Prortfolio",
    component: Portfolio
  },
  {
    path: "/jobs",
    name: "Jobs",
    component: Jobs
  },
  {
    path: "/jobs/:id",
    name: "Job",
    component: Job,
    props: true
  },
  {
    path: "/about",
    name: "About",
    component: About
  },
  {
    path: "/contact",
    name: "Contact",
    component: Contact
  },
  {
    path: "/legal",
    name: "Legal",
    component: Legal
  },
  {
    path: "/apply",
    name: "Apply",
    component: Apply
  },
  {
    path: "/confirmation",
    name: "Confirmation",
    component: Confirmation
  },
  {
    path: "/client-contact",
    name: "Client contact",
    component: ClientRegistration
  },
  {
    path: "/forgot-password",
    name: "Forgot password",
    component: ForgotPassword
  },
  {
    path: "/reset-password",
    name: "Reset password",
    component: ResetPassword
  },
  {
    path: "/set-password",
    name: "Choose password",
    component: SetPassword
  },
  {
    path: "/:slug",
    name: "Simple",
    component: Simple,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes: routes,
  scrollBehavior(to, from, savedPosition) {
    return new Promise(resolve => {
      setTimeout(() => {
        if (savedPosition) {
          resolve(savedPosition);
        } else {
          resolve({ top: 0 });
        }
      }, 1000);
    });
  }
});

export default router;
