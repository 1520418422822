<template>
  <div v-if="!noPageEnd" class="page-end">
    <ul class="footer-menu xs-hidden">
      <li>
        <router-link class="footer-menu__item" active-class="active" to="/about">About</router-link>
      </li>
      <li>
        <router-link
          class="footer-menu__item"
          active-class="active"
          to="/contact"
        >
          Contact
        </router-link>
      </li>
      <li>
        <router-link class="footer-menu__item" active-class="active" to="/legal">Legal</router-link>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  props: {
    white: Boolean,
    noPageEnd: Boolean
  },
  setup() {
    return {};
  }
};
</script>

<style lang="scss" scoped>
@import "~@/assets/style/components/footer.scss";
@import "~@/assets/style/components/footer-menu.scss";
</style>
