<template>
  <div class="models-grid" v-if="locations">
    <div class="models-grid__wrap">
      <div
        v-for="(item, index) in locations"
        :key="index"
        class="models-grid__item locations"
      >
        <div
          class="models-grid__img locations"
          :class="{ 'is-empty': !item.node.profileImage }"
        >
          <img
            v-if="item.node.profileImage"
            :src="`${imageUrl}/${item.node.profileImage.file.thumb.url}`"
            alt=""
            @load="imgLoaded"
          />
          <img
            v-else
            style="display: none"
            :src="require(`@/assets/img/content/${defaultImageName}`)"
            alt=""
            @load="imgLoaded"
          />
        </div>
        <div v-if="item.node.resourceLocation" class="models-grid__title">
          {{ item.node.resourceLocation.name }}
          {{ item.node.resourceLocation.buildYear }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
export default {
  props: {
    locations: Array
  },
  emits: ["update:progress", "update:imagesReady"],
  setup() {
    const loadedImages = ref(0);
    const progress = ref(0);
    const imagesReady = ref(false);
    const imageUrl = process.env.VUE_APP_API_URL;
    const defaultImageName = "default-placeholder-image.png";

    return {
      loadedImages,
      progress,
      imagesReady,
      imageUrl,
      defaultImageName
    };
  },
  mounted() {
    if (this.locations.length == 0) {
      this.imagesReady=true;
      this.$emit("update:imagesReady", this.imagesReady);
    }
  },
  methods: {
    imgLoaded() {
      this.loadedImages++;
      this.progress = (this.loadedImages / this.locations.length) * 100;
      this.$emit("update:progress", this.progress);
      this.$nextTick(() => {
        if (this.loadedImages >= this.locations.length) {
          this.imagesReady = true;
          this.$emit("update:imagesReady", this.imagesReady);
        }
      });
    }
  }
};
</script>

<style lang="scss" scoped>
@import "~@/assets/style/views/castings/modelsGrid.scss";
</style>
