<template>
  <div class="form-group" :class="{ error: v.locationType.$errors.length }">
    <label class="form-label">Location type</label>
    <custom-select
      :options="residenceTypes.map(e => (e.title ? e.title : null))"
      :tabindex="1"
      :defaultValue="'Enter type of location'"
      :error="v.locationType.$errors.length"
      @input="
        event => {
          this.modelLocation.locationType = residenceTypes.filter(
            e => e.title === event
          )[0].id;
        }
      "
      @specify="
        event => {
          this.modelLocation.locationTypeSpecify = event;
        }
      "
    />
    <p
      v-for="(error, index) of v.locationType.$errors"
      :key="index"
      class="error-message"
    >
      {{ error.$message }}
    </p>
  </div>
  <div class="form-group" :class="{ error: v.locationAddress.$errors.length }">
    <label class="form-label">Address</label>
    <address-autocomplete
      v-model:modelData="modelLocation.locationAddress"
      :placeholder="'Enter the address of the location'"
    />
    <p
      v-for="(error, index) of v.locationAddress.$errors"
      :key="index"
      class="error-message"
    >
      {{ error.$message }}
    </p>
  </div>
  <div class="form-group" :class="{ error: v.locationBuilt.$errors.length }">
    <label class="form-label">Year of construction (optional)</label>
    <input
      v-model="modelLocation.locationBuilt"
      class="form-input"
      type="text"
      placeholder="For buildings, enter the approximate year it was built"
    />
    <p
      v-for="(error, index) of v.locationBuilt.$errors"
      :key="index"
      class="error-message"
    >
      {{ error.$message }}
    </p>
  </div>
  <div class="form-group" :class="{ error: v.locationSize.$errors.length }">
    <label class="form-label">Size</label>
    <input
      v-model="modelLocation.locationSize"
      class="form-input"
      type="text"
      placeholder="Enter the approximate size of the location in square meters"
    />
    <p
      v-for="(error, index) of v.locationSize.$errors"
      :key="index"
      class="error-message"
    >
      {{ error.$message }}
    </p>
  </div>
  <div
    class="form-group"
    :class="{ error: v.locationCharacteristics.$errors.length }"
  >
    <label class="form-label">Characteristics</label>
    <textarea
      rows="3"
      v-model="modelLocation.locationCharacteristics"
      class="form-input"
      placeholder="Enter characteristics of the location. E.g. modern, 60s vibe, bohemian"
    />
    <p
      v-for="(error, index) of v.locationCharacteristics.$errors"
      :key="index"
      class="error-message"
    >
      {{ error.$message }}
    </p>
  </div>
  <div
    class="form-group"
    :class="{ error: v.locationDescription.$errors.length }"
  >
    <label class="form-label">Description</label>
    <textarea
      rows="5"
      v-model="modelLocation.locationDescription"
      class="form-input"
      placeholder="Enter a short description, including information about special features such as exceptional view, tiled stoves, etc"
    />
    <p
      v-for="(error, index) of v.locationDescription.$errors"
      :key="index"
      class="error-message"
    >
      {{ error.$message }}
    </p>
  </div>
  <div
    class="form-group"
    :class="{ error: v.locationInstagram.$errors.length }"
  >
    <label class="form-label">Instagram (optional)</label>
    <input
      v-model="modelLocation.locationInstagram"
      class="form-input"
      type="text"
      placeholder="Enter Instagram username or URL of the location"
    />
    <p
      v-for="(error, index) of v.locationInstagram.$errors"
      :key="index"
      class="error-message"
    >
      {{ error.$message }}
    </p>
  </div>
  <div class="form-group" :class="{ error: v.resourceImageIds.$errors.length }">
    <div class="form-label">Images</div>
    <DragAndDrop
      v-model:images="modelLocation.images"
      v-model:resourceImageIds="modelLocation.resourceImageIds"
      resourceType="location"
      @image-added="imageAdded"
      @image-uploaded="imageUploaded"
    />
    <p
      v-for="(error, index) of v.resourceImageIds.$errors"
      :key="index"
      class="error-message"
    >
      {{ error.$message }}
    </p>
  </div>
</template>

<script>
import { reactive, toRef, watch } from "vue";
import { helpers, numeric, required } from "@vuelidate/validators";
import DragAndDrop from "@/components/jobs/DragAndDrop";
import CustomSelect from "@/components/jobs/CustomSelect";
import addressAutocomplete from "@/components/jobs/addressAutocomplete.vue";
import useVuelidate from "@vuelidate/core";
import residenceTypesQuery from "@/graphql/residenceTypes.query.gql";
import { useQuery, useResult } from "@vue/apollo-composable";
export default {
  components: {
    DragAndDrop,
    CustomSelect,
    addressAutocomplete
  },
  props: {
    submitClick: Boolean,
    model: Object
  },
  emits: ["update:model", "modelUpdate", "image-added", "image-uploaded"],
  setup(props, { emit }) {
    const { result } = useQuery(residenceTypesQuery);
    const residenceTypes = useResult(
      result,
      [],
      data => data.dictionary.residenceTypes
    );
    const modelLocation = reactive({
      modelType: "location",
      locationType: "",
      locationTypeSpecify: "",
      locationBuilt: "",
      locationSize: "",
      locationCharacteristics: "",
      locationDescription: "",
      locationInstagram: "",
      locationAddress: "",
      images: [],
      resourceImageIds: []
    });
    watch(
      () => props.submitClick,
      newValue => {
        if (newValue) {
          emit("update:model", modelLocation);
          emit("model-update");
        }
      }
    );
    const typeRequiredErrorMessage = "Type field cannot be empty";
    const addressRequiredErrorMessage = "Address field cannot be empty";
    const sizeRequiredErrorMessage = "Size field cannot be empty";
    const numericErrorMessage = "Size field should be numeric";
    const builtYearErrorMessage = `Year must be before ${new Date().getFullYear()}`;
    const selectRequiredRule = value => (value ? true : false);
    const rules = {
      locationType: {
        required: helpers.withMessage(
          typeRequiredErrorMessage,
          selectRequiredRule
        ),
        $autoDirty: true
      },
      locationAddress: {
        required: helpers.withMessage(addressRequiredErrorMessage, required),
        $autoDirty: true
      },
      locationBuilt: {
        maxValue: helpers.withMessage(
          builtYearErrorMessage,
          value => value <= new Date().getFullYear()
        ),
        $autoDirty: true
      },
      locationSize: {
        required: helpers.withMessage(sizeRequiredErrorMessage, value =>
          value ? true : false
        ),
        numeric: helpers.withMessage(numericErrorMessage, numeric),
        $autoDirty: true
      },
      locationCharacteristics: {},
      locationDescription: {},
      locationInstagram: {},
      resourceImageIds: {
        maxValue: helpers.withMessage(
          "At least one image required",
          value => value && value.length >= 1
        ),
        $autoDirty: true
      }
    };
    const v = useVuelidate(rules, {
      locationType: toRef(modelLocation, "locationType"),
      locationAddress: toRef(modelLocation, "locationAddress"),
      locationBuilt: toRef(modelLocation, "locationBuilt"),
      locationSize: toRef(modelLocation, "locationSize"),
      locationCharacteristics: toRef(modelLocation, "locationCharacteristics"),
      locationDescription: toRef(modelLocation, "locationDescription"),
      locationInstagram: toRef(modelLocation, "locationInstagram"),
      resourceImageIds: toRef(modelLocation, "resourceImageIds")
    });
    function imageAdded(value) {
      emit("image-added", value);
    }
    function imageUploaded(value) {
      emit("image-uploaded", value);
    }
    return {
      modelLocation,
      residenceTypes,
      imageAdded,
      imageUploaded,
      v
    };
  }
};
</script>
