<template>
  <div class="login">
    <transition name="camillaLoader">
      <div v-if="!imagesReady" class="loader"></div>
    </transition>
    <transition name="fadetext">
      <div v-if="!imagesReady" class="loader-text">
        Loading <span>{{ Math.round(progress) }}%</span>
      </div>
    </transition>
    <Header />
    <div class="login__container">
      <form class="form" id="form">
        <div class="form__container container">
          <div>
            <h2 class="form__title">{{ loginTitle }}</h2>
            <div class="form__description">
              <p>
                {{ loginSubtitle }}
              </p>
            </div>
          </div>
          <div
            class="form-group"
            :class="{ error: v.emailAddress.$errors.length }"
          >
            <label class="form-label">E-mail</label>
            <input
              v-model="emailAddress"
              class="form-input"
              type="text"
              placeholder="Enter e-mail"
            />
            <p
              v-for="(error, index) of v.emailAddress.$errors"
              :key="index"
              class="error-message"
            >
              {{ error.$message }}
            </p>
          </div>
          <div class="form-group" :class="{ error: v.password.$errors.length }">
            <label class="form-label">Password</label>
            <input
              v-model="password"
              class="form-input"
              type="password"
              placeholder="Enter password"
            />
            <p
              v-for="(error, index) of v.password.$errors"
              :key="index"
              class="error-message"
            >
              {{ error.$message }}
            </p>
          </div>
          <div class="form__submit">
            <div class="info">
              <a href='/forgot-password'> Forgot password? </a>
            </div>
            <label class="checkbox">
              <input
                v-model="politic"
                class="checkbox__input"
                type="checkbox"
                name="politic"
              />
              <div class="checkbox__icon"></div>
              <div class="checkbox__label">
                Remember me
              </div>
            </label>
            <button
              :disabled="v.$invalid"
              class="btn"
              type="submit"
              @click.prevent="submitHandler"
            >
              login
            </button>
          </div>
        </div>
      </form>
    </div>
    <Footer />
    <teleport to="[data-error-popup]">
      <error-popup
        v-model:showPopup="showErrorPopup"
        :errorMessage="errorMessage"
        :white="true"
      />
    </teleport>
  </div>
</template>

<script>
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
// import FlexTitle from "@/components/castings/FlexTitle.vue";
import useVuelidate from "@vuelidate/core";
import { email, required, helpers } from "@vuelidate/validators";
import { nextTick, onMounted, ref } from "vue";
import { useMutation } from "@vue/apollo-composable";
import signInMutation from "@/graphql/singIn.mutation.gql";
import ErrorPopup from "@/components/ErrorPopup.vue";
export default {
  components: {
    Header,
    Footer,
    // FlexTitle,
    ErrorPopup
  },
  emits: ["loged-in"],
  setup() {
    const loginTitle = "Login";
    const loginSubtitle =
      "Clients, talents and location owners — here’s where you log in.";
    const emailAddress = ref("");
    const password = ref("");
    const emailRequiredErrorMessage = "Email field cannot be empty";
    const emailErrorMessage = "Email not valid";
    const passwordErrorMessage = "Password field cannot be empty";
    const politic = ref(false);
    const showErrorPopup = ref(false);
    const errorMessage = ref("");
    const progress = 100;
    const imagesReady = ref(false);
    onMounted(() => {
      setTimeout(() => {
        imagesReady.value = true;
      }, 100);
    });
    const { mutate: singIn } = useMutation(signInMutation, {
      context: {
        headers: {
          "JWT-AUD": "web"
        }
      }
    });
    const rules = {
      emailAddress: {
        required: helpers.withMessage(emailRequiredErrorMessage, value =>
          value ? true : false
        ),
        email: helpers.withMessage(emailErrorMessage, email),
        $autoDirty: true
      },
      password: {
        required: helpers.withMessage(passwordErrorMessage, required),
        $autoDirty: true
      }
    };
    const v = useVuelidate(rules, {
      emailAddress,
      password
    });
    return {
      loginTitle,
      loginSubtitle,
      emailAddress,
      password,
      politic,
      singIn,
      showErrorPopup,
      errorMessage,
      imagesReady,
      progress,
      v
    };
  },
  methods: {
    submitHandler() {
      this.v.$touch();
      if (this.v.$error) {
        return;
      }
      nextTick(() => {
        this.singIn({
          input: {
            email: this.emailAddress,
            password: this.password
          }
        })
          .then(({ data }) => {
            if (!data.signIn.session) {
              this.errorHandler(data);
              return;
            }
            const token = data.signIn.session.token;
            window.localStorage.setItem("lomoCastToken", token);
            if (data.signIn.session.user) {
              window.localStorage.setItem(
                "user",
                JSON.stringify(data.signIn.session.user)
              );
              // this.store.dispatch("onFetchUser", data.signIn.session.user);
              this.$emit("loged-in", false);
            }
            window.location.href = "/my";
          })
          .catch(e => {
            this.errorHandler(e);
          });
      });
    },
    errorHandler(error) {
      window.localStorage.removeItem("lomoCastToken");
      this.showErrorPopup = true;
      if (error.signIn.errors) {
        this.errorMessage = `${error.signIn.errors[0].message}`;
        return;
      }
      this.errorMessage =
        "Something went wrong...\nPlease check email and password";
    }
  }
};
</script>

<style lang="scss" scoped>
@import "~@/assets/style/views/home/form.scss";
@import "~@/assets/style/views/login/login.scss";
</style>
