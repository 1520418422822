<template>
  <div class="form-group" :class="{ error: v.animalName.$errors.length }">
    <label class="form-label">Name</label>
    <input
      v-model="modelAnimal.animalName"
      class="form-input"
      type="text"
      placeholder="Enter name of animal"
    />
    <p
      v-for="(error, index) of v.animalName.$errors"
      :key="index"
      class="error-message"
    >
      {{ error.$message }}
    </p>
  </div>
  <div class="form-group" :class="{ error: v.animalBirth.$errors.length }">
    <label class="form-label">Year of birth</label>
    <div class="row">
      <div class="col-4 col-sm-12 form-col">
        <custom-select
          :options="yearList"
          :tabindex="1"
          :defaultValue="'Year'"
          :error="v.animalBirth.$errors.length"
          @input="
            event => {
              this.modelAnimal.animalBirth = event;
            }
          "
        />
      </div>
    </div>
    <p
      v-for="(error, index) of v.animalBirth.$errors"
      :key="index"
      class="error-message"
    >
      {{ error.$message }}
    </p>
  </div>
  <div class="form-group" :class="{ error: v.animalGender.$errors.length }">
    <label class="form-label">Gender</label>
    <custom-select
      :options="['Female', 'Male']"
      :tabindex="1"
      :defaultValue="'Animal gender'"
      :error="v.animalGender.$errors.length"
      @input="
        event => {
          this.modelAnimal.animalGender = event;
        }
      "
    />
    <p
      v-for="(error, index) of v.animalGender.$errors"
      :key="index"
      class="error-message"
    >
      {{ error.$message }}
    </p>
  </div>
  <div class="form-group" :class="{ error: v.animalType.$errors.length }">
    <label class="form-label">Type</label>
    <autocomplete
      :options="animalTypesList"
      :placeholder="'Enter type of animal'"
      :error="v.animalType.$errors.length"
      @selected="
        event => {
          this.modelAnimal.animalType = event;
        }
      "
    />
    <p
      v-for="(error, index) of v.animalType.$errors"
      :key="index"
      class="error-message"
    >
      {{ error.$message }}
    </p>
  </div>
  <div class="form-group" :class="{ error: v.animalBreed.$errors.length }">
    <label class="form-label">Breed (optional)</label>
    <autocomplete
      :options="animalBreedList.map(item => item.title)"
      :placeholder="'Enter breed of animal'"
      :error="v.animalBreed.$errors.length"
      @selected="
        event => {
          this.modelAnimal.animalBreed = event;
        }
      "
    />
    <p
      v-for="(error, index) of v.animalBreed.$errors"
      :key="index"
      class="error-message"
    >
      {{ error.$message }}
    </p>
  </div>
  <div class="form-group" :class="{ error: v.animalHeight.$errors.length }">
    <label class="form-label">Height</label>
    <input
      v-model="modelAnimal.animalHeight"
      class="form-input"
      type="text"
      placeholder="Enter height of withers in centimeters"
    />
    <p
      v-for="(error, index) of v.animalHeight.$errors"
      :key="index"
      class="error-message"
    >
      {{ error.$message }}
    </p>
  </div>
  <div class="form-group" :class="{ error: v.animalAddress.$errors.length }">
    <label class="form-label">Address</label>
    <address-autocomplete
      v-model:modelData="modelAnimal.animalAddress"
      :placeholder="'Enter the primary location of the animal'"
    />
    <p
      v-for="(error, index) of v.animalAddress.$errors"
      :key="index"
      class="error-message"
    >
      {{ error.$message }}
    </p>
  </div>
  <div class="form-group" :class="{ error: v.animalInstagram.$errors.length }">
    <label class="form-label">Instagram (optional)</label>
    <input
      v-model="modelAnimal.animalInstagram"
      class="form-input"
      type="text"
      placeholder="Enter Instagram username or URL of the animal"
    />
    <p
      v-for="(error, index) of v.animalInstagram.$errors"
      :key="index"
      class="error-message"
    >
      {{ error.$message }}
    </p>
  </div>
  <div class="form-group" :class="{ error: v.resourceImageIds.$errors.length }">
    <div class="form-label">Images</div>
    <DragAndDrop
      v-model:images="modelAnimal.images"
      v-model:resourceImageIds="modelAnimal.resourceImageIds"
      resourceType="animal"
      @image-added="imageAdded"
      @image-uploaded="imageUploaded"
    />
    <p
      v-for="(error, index) of v.resourceImageIds.$errors"
      :key="index"
      class="error-message"
    >
      {{ error.$message }}
    </p>
  </div>
</template>

<script>
import { computed, reactive, toRef, watch } from "vue";
import { helpers, numeric, required, integer } from "@vuelidate/validators";
import DragAndDrop from "@/components/jobs/DragAndDrop";
import CustomSelect from "@/components/jobs/CustomSelect";
import addressAutocomplete from "@/components/jobs/addressAutocomplete.vue";
import Autocomplete from "@/components/jobs/Autocomplete.vue";
import useVuelidate from "@vuelidate/core";
import animalGroupsQuery from "@/graphql/animalGroups.query.gql";
import { useQuery, useResult } from "@vue/apollo-composable";
export default {
  components: {
    DragAndDrop,
    CustomSelect,
    addressAutocomplete,
    Autocomplete
  },
  props: {
    submitClick: Boolean,
    model: Object
  },
  emits: ["update:model", "modelUpdate", "image-added", "image-uploaded"],
  setup(props, { emit }) {
    const { result } = useQuery(animalGroupsQuery);
    const animalGroups = useResult(
      result,
      [],
      data => data.dictionary.animalGroups
    );
    const modelAnimal = reactive({
      modelType: "animal",
      animalName: "",
      animalBirth: "",
      animalGender: "",
      animalType: "",
      animalBreed: "",
      animalHeight: "",
      animalInstagram: "",
      animalAddress: "",
      images: [],
      resourceImageIds: []
    });
    const animalTypesList = computed(() =>
      animalGroups.value.map(group => group.title)
    );
    const animalBreedList = computed(() =>
      animalGroups.value.filter(
        group => group.title === modelAnimal.animalType
      )[0]
        ? animalGroups.value.filter(
            group => group.title === modelAnimal.animalType
          )[0].animalBreeds
        : []
    );
    const yearList = [];
    const currentYear = new Date().getFullYear();
    for (let i = 30; i > -1; i--) {
      yearList.push(currentYear - i);
    }
    watch(
      () => props.submitClick,
      newValue => {
        if (newValue) {
          emit("update:model", modelAnimal);
          emit("model-update");
        }
      }
    );
    const nameRequiredErrorMessage = "Name field cannot be empty";
    const birthRequiredErrorMessage = "Birth field cannot be empty";
    const genderRequiredErrorMessage = "Gender field cannot be empty";
    const typeRequiredErrorMessage = "Type field cannot be empty";
    const addressRequiredErrorMessage = "Address field cannot be empty";
    const heightRequiredErrorMessage = "Height field cannot be empty";
    const numericErrorMessage = "Height field should be numeric";
    const integerHeightErrorMessage = "Height must be integer";
    const minHeightErrorMessage = "Height must be greater than or equal to 0";
    const maxHeightErrorMessage = "Height must be less than or equal to 1000";
    const selectRequiredRule = value => (value ? true : false);
    const rules = {
      animalName: {
        required: helpers.withMessage(nameRequiredErrorMessage, required),
        $autoDirty: true
      },
      animalBirth: {
        required: helpers.withMessage(
          birthRequiredErrorMessage,
          selectRequiredRule
        ),
        $autoDirty: true
      },
      animalGender: {
        required: helpers.withMessage(genderRequiredErrorMessage, value =>
          value ? true : false
        ),
        $autoDirty: true
      },
      animalType: {
        required: helpers.withMessage(typeRequiredErrorMessage, value =>
          value ? true : false
        ),
        $autoDirty: true
      },
      animalBreed: {},
      animalHeight: {
        required: helpers.withMessage(heightRequiredErrorMessage, value =>
          value ? true : false
        ),
        numeric: helpers.withMessage(numericErrorMessage, numeric),
        integer: helpers.withMessage(integerHeightErrorMessage, integer),
        minValue: helpers.withMessage(
          minHeightErrorMessage,
          value => value >= 0
        ),
        maxValue: helpers.withMessage(
          maxHeightErrorMessage,
          value => value <= 1000
        ),
        $autoDirty: true
      },
      animalAddress: {
        required: helpers.withMessage(addressRequiredErrorMessage, value =>
          value ? true : false
        ),
        $autoDirty: true
      },
      animalInstagram: {},
      resourceImageIds: {
        maxValue: helpers.withMessage(
          "At least one image required",
          value => value && value.length >= 1
        ),
        $autoDirty: true
      }
    };
    const v = useVuelidate(rules, {
      animalName: toRef(modelAnimal, "animalName"),
      animalBirth: toRef(modelAnimal, "animalBirth"),
      animalGender: toRef(modelAnimal, "animalGender"),
      animalType: toRef(modelAnimal, "animalType"),
      animalBreed: toRef(modelAnimal, "animalBreed"),
      animalHeight: toRef(modelAnimal, "animalHeight"),
      animalAddress: toRef(modelAnimal, "animalAddress"),
      animalInstagram: toRef(modelAnimal, "animalInstagram"),
      resourceImageIds: toRef(modelAnimal, "resourceImageIds")
    });
    function imageAdded(value) {
      emit("image-added", value);
    }
    function imageUploaded(value) {
      emit("image-uploaded", value);
    }
    return {
      modelAnimal,
      yearList,
      animalTypesList,
      animalBreedList,
      animalGroups,
      imageAdded,
      imageUploaded,
      v
    };
  }
};
</script>
