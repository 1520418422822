<template>
  <div class="portfolios portfolios-grid">
    <div class="container portfolios__container">
      <div class="portfolios__row_inner row">
        <div v-if="portfolios.length" class="col-6 col-md-12 portfolios__col">
          <router-link
            :to="`/portfolio/${portfolios[0].slug}`"
            class="portfolios__item portfolios-item"
          >
            <div class="portfolios-item__img">
              <img
                :srcset="portfolios[0].acf.thumbnail.srcset"
                :src="portfolios[0].acf.thumbnail.sizes.large"
                alt=""
              />
            </div>
            <div class="portfolios-item__title">
              {{ portfolios[0].title.rendered }}
            </div>
          </router-link>
        </div>
        <div class="col-6 col-md-12 portfolios__col">
          <div class="row portfolios__row_inner">
            <div
              v-for="(portfolio, index) in portfolios.slice(1, 5)"
              :key="index"
              class="col-6 col-sm-12 portfolios__col"
            >
              <router-link
                :to="`/portfolio/${portfolio.slug}`"
                class="portfolios__item portfolios-item"
              >
                <div class="portfolios-item__img">
                  <img
                    :srcset="portfolio.acf.thumbnail.srcset"
                    :src="portfolio.acf.thumbnail.sizes.large"
                    alt=""
                  />
                </div>
                <div class="portfolios-item__title">
                  {{ portfolio.title.rendered }}
                </div>
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed } from "vue";
import { useStore } from "vuex";
export default {
  setup() {
    const store = useStore();
    const portfolios = computed(() =>
      store.state.portfolios.filter(el => el.acf.featured === true)
    );
    // onMounted(() => {
    //   store.dispatch("onFetchPortfolios", { category: -1 });
    // });

    return {
      portfolios
    };
  }
};
</script>

<style lang="scss" scoped>
@import "~@/assets/style/views/home/portfolios.scss";
.portfolios-grid {
  padding-top: 56px;
  @media screen and (max-width: 767px) {
    padding-top: 45px;
  }
}
</style>
